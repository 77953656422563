<div role="group" [formGroup]="parts" >
  <div class="time-container">
    <input type="text" #hours class="hours" formControlName="hours" maxlength="2" (focusout)="hoursFocusOut()">
    <span class="colon-span">&#58;</span>
    <input #minutes class="minutes" formControlName="minutes" maxlength="2" (focusout)="minutesFocusOut()">
  </div>
  <button type="button" class="dropdown-button" matSuffix mat-icon-button [disabled]="disabled" (click)="menuButtonClicked()">
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
  <mat-select #timeSelect class="behind-select" (selectionChange)="selectValueChanged($event);" formControlName="dropdownValue">
    <mat-option *ngFor="let option of matOptions" [value]="option.value">{{ option.text }}</mat-option>
  </mat-select>
</div>