<div class="row align-items-center">
  <div class="col">
    <p class="message-text">{{ message | translate }}</p>
  </div>
</div>
<div class="row align-items-center">
  <div class="col">
    <button class="float-right" mat-raised-button color="primary" (click)="dismiss()">{{ 'Common.Ok' | translate }}</button>
  </div>
</div>
