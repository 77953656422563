<h1 mat-dialog-title>{{ 'Commmon.StatsticQueryNameDialog.CreateTitle' | translate }}</h1>

<mat-dialog-content>
  <form [formGroup]="formGroup" autocomplete="off">
    <div class="row pt-2">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <mat-form-field class="w-100">
              <mat-label>{{ 'Common.Entities.StatisticQuery.Name' | translate }}</mat-label>
              <input matInput type="text" [formControl]="nameControl" />
              <mat-error *ngIf="hasError(nameControl, 'required')">{{ 'Common.Validation.Required' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button (click)="onCancel()">
    {{ 'Common.Close' | translate}}
  </button>
  <button type="button" mat-raised-button color="primary" class="ms-2" (click)="onSave()">
    {{ 'Common.Save' | translate}}
  </button>
</mat-dialog-actions>
