import { BehaviorSubject } from 'rxjs';
import { Validators, UntypedFormBuilder, AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { BaseFilterComponent } from '../base-filter.component';
import { MatSelectChange } from '@angular/material/select';
import { StringFilterType } from 'src/app/core/models/api-models';
import { FilterType } from 'src/app/core/common/filter-type';
import { Filter } from 'src/app/core/models/filter-paging.model';
import EnumHelper from 'src/app/core/helpers/enum.helper';

@Component({
  selector: 'app-string-filter',
  templateUrl: './string-filter.component.html',
  styleUrls: ['./string-filter.component.scss']
})
export class StringFilterComponent extends BaseFilterComponent<StringFilterType> implements OnInit {

  stringFilterType = StringFilterType;

  filterForm = new FormGroup({
    filterType: new FormControl<StringFilterType>(StringFilterType.Contains, { validators: [Validators.required], nonNullable: true },),
  });

  stringForm = new FormGroup({
    value: new FormControl<string | null>(null, { validators: [Validators.required] }),
  });

  filterOptions = this.getFilterOptions();
  currentFilter = new BehaviorSubject<StringFilterType>(StringFilterType.Contains);
  filterType: FilterType = FilterType.String;


  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  clearFilter(): void {
    this.currentValue.next(null);
    this.activeFilterField = this.currentValue.value !== null;
    this.filterForm.reset();
    this.stringForm.reset();
  }

  initValue(value: Filter<StringFilterType>): void {
    this.stringForm.markAllAsTouched();
    this.currentFilter.next(value.filterType);
    this.stringControl.setValue(value.values[0]);
    this.filterTypeControl.setValue(value.filterType);
    this.currentValue.next(value);
    this.activeFilterField = this.currentValue.value !== null;
  }

  getFilterOptions(): { value: number, translationKey: string }[] {
    const options: { value: number, translationKey: string }[] = [];

    Object.keys(StringFilterType).filter((enumVal) => !isNaN(Number(enumVal))).forEach((enumVal: any) => {
      options.push({
        value: parseInt(enumVal, 10),
        translationKey: `Common.Filters.StringFilters.${StringFilterType[enumVal]}`
      });
    });

    return options;
  }

  onFilterChange($event: MatSelectChange): void {
    this.currentFilter.next($event.value as StringFilterType);
  }

  onSubmit($event: any): void {

    // Trigger form validation for untouched fields
    this.stringForm.markAllAsTouched();

    if (this.stringForm.valid) {
      const model: Filter<StringFilterType> = {
        filterType: this.currentFilter.value,
        values: [this.stringControl.value, null] as any[],
        name: this.propertyName
      };

      this.currentValue.next(model);
      this.filterChanged.emit({ filter: this.currentValue.value, filterType: this.filterType, propertyName: this.propertyName });
      this.activeFilterField = this.currentValue.value !== null;
    } else {
      $event.stopPropagation();
    }
  }

  onCancel(): void {
  }

  get stringControl(): FormControl {
    return this.stringForm.get('value')! as FormControl;
  }

  get filterTypeControl(): FormControl {
    return this.filterForm.get('filterType')! as FormControl;
  }

}
