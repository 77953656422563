<div class="row align-items-center">
    <div class="col">
      <ng-container *ngFor="let text of texts">
        {{ text[0] | translate: text[1]}}<br />
      </ng-container>
    </div>
  </div>
  <div class="row align-items-center">
    <div class="col text-right">
      <button mat-raised-button color="primary" (click)="dismiss()">{{ 'Common.Ok' | translate }}</button>
    </div>
  </div>