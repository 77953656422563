import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, map, switchMap } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { AppConfigService } from "./app-config.service";
import { LanguageModel } from "../models/api-models";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class LanguageService {
  public static readonly defaultLanguage: string = 'de-CH';
  private readonly apiUrl = 'api/Language/';

  private readonly cookieExpirationDays = 365 * 20; // 20 years
  private systemLanguages: LanguageModel[] = [];

  private readonly currentLanguageCodeSubject = new BehaviorSubject<string>(LanguageService.defaultLanguage);
  private currentLanguageCodeString: string = LanguageService.defaultLanguage;

  constructor(
    private readonly http: HttpClient,
    private readonly translateService: TranslateService,
    private readonly cookieService: CookieService,
    private readonly appConfigService: AppConfigService // Do not access values directly in constructor because this is part of initial factory
  ) {
  }

  get currentLanguageCode$(): Observable<string> {
    return this.currentLanguageCodeSubject.asObservable();
  }

  get currentLanguageCode(): string {
    return this.currentLanguageCodeString;
  }

  async init(): Promise<any> {
    this.currentLanguageCodeSubject.subscribe(lang => {
      this.currentLanguageCodeString = lang;
    });

    return new Promise<string>((resolve, reject) => {
      this.getLanguages().pipe(switchMap(languages => { // Return an other observable after this observable executed
        this.systemLanguages = languages ?? [];
        //this.translateService.setDefaultLang(LanguageService.defaultLanguage);
        const currentLanguage = this.detectLanguage();

        return this.setLanguageByCode(currentLanguage);
      }))
        .subscribe(language => {
          resolve(language);
        });
    });
  }

  getLanguages(): Observable<LanguageModel[]> {
    const config = this.appConfigService.getConfig();
    return this.http.get<LanguageModel[]>(`${config.apiUrl}/api/Language`);
  }

  setLanguageByCode(code: string): Observable<any> {
    return this.translateService.use(code)
      .pipe(map(value => { // Return a new Observable of a resulting value after this observable finished
        const config = this.appConfigService.getConfig();

        // Set cookie (Only relevant on client side)
        this.cookieService.set(config.cookieCulture, this.getCookieValueFromCode(code), this.cookieExpirationDays, '/');

        this.currentLanguageCodeSubject.next(code);

        return code;
      }));
  }

  public detectLanguage(): string {
    let languageFound = false;
    let currentLanguage: string = LanguageService.defaultLanguage;

    // Use language from cookie (Set by user)
    if (!languageFound) {
      const cookieName = this.appConfigService.getConfig().cookieCulture;
      if (this.cookieService.check(cookieName)) {
        currentLanguage = this.getCodeFromCookieValue(this.cookieService.get(cookieName));
        languageFound = true;
      }
    }

    // Use language from browser (First time access by user)
    if (!languageFound) {
      // Use browser language
      const browserLanguage = navigator.language;

      if (browserLanguage) {
        const browserLanguagePrefix = browserLanguage.substring(0, 2);

        currentLanguage = this.getLanguageByPrefix(browserLanguagePrefix);
        languageFound = true;
      }
    }

    return currentLanguage;
  }

  getCookieValueFromCode(code: string): string {
    // .NET Core format
    return `c=${code}|uic=${code}`;
  }

  getCodeFromCookieValue(cookieValue: string): string {
    return cookieValue.substring(2, 7);
  }

  private getLanguageByPrefix(prefix: string): string {
    return this.systemLanguages.find(language => language.languageCode.substring(0, 2) === prefix)?.languageCode ?? LanguageService.defaultLanguage;
  }
}
