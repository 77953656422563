import { Component, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from 'src/app/core/components/base.component';

@Component({
  selector: 'app-basket-expires-dialog',
  templateUrl: './basket-expires-dialog.component.html',
  styleUrls: ['./basket-expires-dialog.component.scss']
})
export class BasketExpiresDialogComponent extends BaseComponent { // doesn't inherit from basedialog component because of backdrop click
  public renewClicked = false;

  constructor(protected readonly element: ElementRef,
    protected readonly matDialogRef: MatDialogRef<BasketExpiresDialogComponent>) {
    super();
  }

  ngOnInit() {
  }

  onCancel(): void {
    this.matDialogRef.close();
  }

  onRenew(): void {
    this.renewClicked = true;
    this.matDialogRef.close();
  }
}
