import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  templateUrl: './confirm-message.component.html',
  styleUrls: ['./confirm-message.component.scss']
})
export class ConfirmMessageComponent {
  isConfirmed: boolean = false;

  constructor(
    private matSnackBarRef: MatSnackBarRef<ConfirmMessageComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {

   }

  ok(): void {
    this.isConfirmed = true;
    this.dismiss();
  }

  cancel(): void {
    this.isConfirmed = false;
    this.dismiss();
  }

  private dismiss(): void {
    this.matSnackBarRef.dismiss();
  }
}
