import { AbstractControl, FormControl, ValidatorFn } from "@angular/forms";
import { conditionalValidator } from "./conditional.validator";
import { emailValidator } from "./email.validator";
import { largerThanValidator } from "./larger-than.validator";
import { passwordMatchValidator } from "./password-match.validator";
import { passwordValidator } from "./password.validator";
import { phoneValidator } from "./phone.validator";
import { smallerThanValidator } from "./smaller-than.validator";
import { numberValidator } from "./number.validator";
import { largerThanOrEqualValueValidator } from "./larger-than-or-equal-value.validator";
import { internationalPhoneValidator } from "./international-phone.validator";
import { nubmerOrWhitespaceValidator } from "./numbersAndWhitespace.validator";
import { customValidator } from "./custom.validator";
import { largerThanValueValidator } from "./larger-than-value.validator";
import { smallerThanValueValidator } from "./smaller-than-value.validator";
import { smallerThanOrEqualValueValidator } from "./smaller-than-or-equal-value.validator";
import { internationalGooglePhoneValidator } from "./international-google-phone-number.validator";
import { largerThanOrEqualValidator } from "./larger-than-or-equal-validator";
import { largerThenDateValidator } from "./larger-then-date.validator";
import { smallerThenDateValidator } from "./smaller-then-date.validator";
import { largerThanDateOrEqualValidator } from "./larger-than-date-or-equal.validator";
import { smallerThanDateOrEqualValidator } from "./smaller-than-date-or-equal.validator";

export default class CustomValidators {
  static conditional(predicate: (control: AbstractControl) => boolean, validator: any): ValidatorFn {
    return conditionalValidator(predicate, validator);
  }

  static smallerThan(smallerThanControl: () => AbstractControl): ValidatorFn {
    return smallerThanValidator(smallerThanControl);
  }

  static smallerThenDate(smallerThenDateControl: () => AbstractControl): ValidatorFn {
    return smallerThenDateValidator(smallerThenDateControl);
  }

  static smallerThanDateOrEqual(smallerThanDateOrEqualControl: () => AbstractControl): ValidatorFn {
    return smallerThanDateOrEqualValidator(smallerThanDateOrEqualControl);
  }

  static largerThan(largerThanControl: () => AbstractControl): ValidatorFn {
    return largerThanValidator(largerThanControl);
  }

  static largerThanOrEqual(largerThanOrEqualControl: () => AbstractControl): ValidatorFn {
    return largerThanOrEqualValidator(largerThanOrEqualControl);
  }

  static largerThanOrEqualValue(value: () => any, conversionLeft?: (value: any) => any, conversionRight?: (value: any) => any): ValidatorFn {
    return largerThanOrEqualValueValidator(value, conversionLeft, conversionRight);
  }

  static largerThanValue(value: () => any, conversionLeft?: (value: any) => any, conversionRight?: (value: any) => any): ValidatorFn {
    return largerThanValueValidator(value, conversionLeft, conversionRight);
  }

  static largerThenDate(largerThenDateControl: () => AbstractControl): ValidatorFn {
    return largerThenDateValidator(largerThenDateControl);
  }

  static largerThanDateOrEqual(largerThanDateOrEqualControl: () => AbstractControl): ValidatorFn {
    return largerThanDateOrEqualValidator(largerThanDateOrEqualControl);
  }

  static smallerThanOrEqualValue(value: () => any, conversionLeft?: (value: any) => any, conversionRight?: (value: any) => any): ValidatorFn {
    return smallerThanOrEqualValueValidator(value, conversionLeft, conversionRight);
  }

  static smallerThanValue(value: () => any, conversionLeft?: (value: any) => any, conversionRight?: (value: any) => any): ValidatorFn {
    return smallerThanValueValidator(value, conversionLeft, conversionRight);
  }

  static get email(): ValidatorFn {
    return emailValidator();
  }

  static get phone(): ValidatorFn {
    return phoneValidator();
  }

  static get internationalPhone(): ValidatorFn {
    return internationalPhoneValidator();
  }

  static get password(): ValidatorFn {
    return passwordValidator();
  }

  static number(min: number | null, max: number | null): ValidatorFn {
    return numberValidator(min, max);
  }

  static passwordMatch(passwordControl: () => AbstractControl): ValidatorFn {
    return passwordMatchValidator(passwordControl);
  }

  static get numbersAndWhitespace(): ValidatorFn {
    return nubmerOrWhitespaceValidator();
  }

  static custom(predicate: (control: AbstractControl) => boolean, returnValue: (checkResult: any) => { [key: string]: any } | null): ValidatorFn {
    return customValidator(predicate, returnValue);
  }

  static internationalGooglePhoneNumber(): ValidatorFn {
    return internationalGooglePhoneValidator();
  }

  static atLeastOneLocationCheckboxChecked(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const room = control.get('room')?.value;
      const internalRestaurant = control.get('internalRestaurant')?.value;
      const otherLocation = control.get('otherLocation')?.value;
  
      if (room || internalRestaurant || otherLocation) {
        return null;
      } else {
        return { atLeastOneCheckboxRequired: true };
      }
    };
  }
}

