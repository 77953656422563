<div class="row">
  <div class="col">
    <span>{{ data.message }}</span>
  </div>
</div>
<div class="row mt-2">
  <div class="col-6 text-start">
    <button mat-raised-button *ngIf="data.secondaryButtonLabel" (click)="cancel()">{{ data.secondaryButtonLabel }}</button>
  </div>
  <div class="col-6 text-end">
    <button mat-raised-button color="primary" (click)="ok()">{{ data.primaryButtonLabel }}</button>
  </div>
</div>
