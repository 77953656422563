import { Observable, fromEvent, Subscription, switchMap, map } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, AbstractControl, UntypedFormArray, FormGroup, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from '../services/message.service';
import { BaseComponent } from '../components/base.component';
import { TranslateService } from '@ngx-translate/core';

export default class FormHelper {
  static getDirtyControlNames(formGroup: UntypedFormGroup): string[] {
    const changedProperties: any = [];

    Object.keys(formGroup.controls).forEach((name) => {
      const currentControl = formGroup.controls[name];

      if (currentControl.dirty) {
        changedProperties.push(name);
      }
    });

    return changedProperties;
  }

  static getDirtyFormControls(formGroup: UntypedFormGroup): UntypedFormControl[] {
    const changedProperties: any = [];

    Object.keys(formGroup.controls).forEach((name) => {
      const control = formGroup.controls[name];

      if (control.dirty) {
        changedProperties.push(control);
      }
    });

    return changedProperties;
  }

  static isOnlyDirtyFormControl(formGroup: UntypedFormGroup, formControl: AbstractControl): boolean {
    const dirtyControls = this.getDirtyFormControls(formGroup);

    return dirtyControls.length === 1 && dirtyControls[0] === formControl;
  }

  static dirtyCheckBeforeUnload(observable: Observable<boolean>, component: BaseComponent): Subscription {
    return fromEvent(window, 'beforeunload')
      .unsubscribeOnDestroy(component)
      .subscribe(event => {
        const subscription = observable.subscribe(value => {
          if (value) {
            event.returnValue = false;
          }
        });

        subscription.unsubscribe();
      });
  }

  static dirtyCheckMultipleFormGroups(formGroups: () => FormGroup[]): boolean {
    return formGroups().some(formGroup => formGroup?.dirty);
  }

  static redirectAndReload(router: Router, uri: string): any {
    return router.navigateByUrl('/reload', { skipLocationChange: true }).then(() =>
      router.navigateByUrl(uri));
  }

  static reloadSelf(router: Router): any {
    return this.redirectAndReload(router, router.url);
  }

  static openDeleteConfirm(component: BaseComponent, messageService: MessageService, translateService: TranslateService, onConfirm: () => void) {
    translateService.get(['Common.Messages.DeleteConfirmation', 'Common.Delete'])
      .unsubscribeOnDestroy(component)
      .pipe(switchMap(translations => {
        const snackBarRef = messageService.openConfirm(translations['Common.Messages.DeleteConfirmation'], translations['Common.Delete']);

        return snackBarRef
          .afterDismissed()
          .pipe(map(() => snackBarRef.instance.isConfirmed));
      }))
      .unsubscribeOnDestroy(component)
      .subscribe(isConfirmed => {
        if (isConfirmed) {
          onConfirm();
        }
      });
  }

  static get priceInputFormat(): string {
    return '^\\d+(?:\\.\\d{1,2})?$';
  }

  static get numericInputFormat(): string {
    return '^[0-9]+$';
  }

  static getFileSize(bytes: number): string {
    let size: number;
    let unit: string;

    if (bytes < 1024) {
      size = bytes;
      unit = "B";
    }
    else if (bytes < 1024 * 1024) {
      size = bytes / 1024;
      unit = "kB";
    }
    else {
      size = bytes / (1024 * 1024);
      unit = "MB";
    }

    return `${size.toFixed(2)} ${unit}`;
  }

  // Can be used to trigger an autocomplete on focus
  static updateControl(control: AbstractControl): void {
    if (!control.value) {
      control.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    }
  }

  // Can be used to update validity of conditional validators
  static updateAllValueAndValidity(group: UntypedFormGroup | UntypedFormArray, emitEvent: boolean = false): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);

      if (abstractControl instanceof UntypedFormGroup || abstractControl instanceof UntypedFormArray) {
        FormHelper.updateAllValueAndValidity(abstractControl);
      } else {
        abstractControl?.updateValueAndValidity({ emitEvent: emitEvent });
      }
    });
  }

  static isTouchedAndInvalid(control: AbstractControl): boolean {
    return control.touched && control.invalid;
  }

  static toggleValidatorForControls(
    formControls: FormControl[],
    hasValidator: boolean,
    validator: ValidatorFn = Validators.required,
    emitEvent: boolean = true
  ): void {
    formControls.forEach(control => {
      if (hasValidator) {
        control.addValidators(validator);
      } else {
        control.removeValidators(validator);
      }

      control.updateValueAndValidity({ emitEvent });
    });
  }
}
