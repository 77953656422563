import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileDataService } from 'src/app/core/services/api-services';
import { FileDataModel } from 'src/app/core/models/api-models';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { Buffer } from 'buffer';
import { AppConfigService } from 'src/app/core/services/app-config.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  files: NgxFileDropEntry[] = [];
  fileErrorTranslationCode?: string;

  transValue: any;

  documentName?: string;

  @Input() documents: FileDataModel[] = [];
  @Input() title?: string;
  @Input() allowedFileExtensions?: string[];
  @Input() allowedMimeTypes?: string[];
  @Input() maxFileSize?: number;
  @Input() multiple?: boolean;
  @Input() disabled?: boolean;
  @Input() singleFileOnly?: boolean;
  @Input() openInsteadOfDownload?: boolean;
  @Input() showBottom: boolean = false;
  @Input() hiddenDocuments: FileDataModel[] = [];

  @Output() dropped: EventEmitter<FileDataModel> = new EventEmitter<FileDataModel>();
  @Output() removedDocument: EventEmitter<FileDataModel> = new EventEmitter<FileDataModel>();

  constructor(
    private readonly fileDataService: FileDataService,
    private readonly appConfigService: AppConfigService
  ) { }

  ngOnInit(): void {
  }

  onDrop(files: NgxFileDropEntry[]): void {
    this.fileErrorTranslationCode = undefined;
    this.documentName = undefined;
    this.files = files;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const splitedName = droppedFile.fileEntry.name.split('.');
        const extension = splitedName.pop();
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          if (this.maxFileSize && (file.size / 1024) > this.maxFileSize) {
            this.fileErrorTranslationCode = 'Common.Upload.FileSizeTooLarge';
            this.transValue = { fileSize: this.maxFileSize / 1024 };

            return;
          }

          if (this.allowedFileExtensions && !this.allowedFileExtensions.filter(x => x === extension)?.length) {
            this.fileErrorTranslationCode = 'Common.Upload.ExtensionNotAllowed';

            return;
          }

          if (this.documents.filter(x => x.filename === file.name)?.length > 0 || this.hiddenDocuments.filter(x => x.filename === file.name)?.length > 0) {
            this.fileErrorTranslationCode = 'Common.Upload.SameDocumentName';
            this.transValue = { documentName: file.name };

            return;
          }

          if (droppedFile.fileEntry.isFile) {
            const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => {

              if (this.allowedMimeTypes && !this.allowedMimeTypes.filter(x => x === file.type)?.length) {
                this.fileErrorTranslationCode = 'Common.Upload.ExtensionNotAllowed';

                return;
              }

              const byteReader = new FileReader();

              byteReader.onload = () => {
                const dataUrl = byteReader.result as string;

                const fileData = {
                  data: dataUrl.split(',')[1],
                  filename: file.name,
                  contentType: file.type
                } as FileDataModel;

                this.dropped.emit(fileData);
              };

              byteReader.readAsDataURL(file);
            });
          }
        });
      }
    }
  }

  removeDocument(document: FileDataModel): void {
    const index: number = this.documents.indexOf(document);
    if (index !== -1) {
      this.removedDocument.emit({ ...document });
    }
  }

  downloadDocument(document: FileDataModel): void {
    if (document.id) {
      this.fileDataService.get(document.id!).subscribe(data => {
        this.downloadFile(data!);
      });
    } else {
      this.downloadFile(document);
    }
  }

  private downloadFile(data: FileDataModel): void {
    var byteArray = Buffer.from(data.data!, 'base64');
    const blob: any = new Blob([byteArray], { type: data.contentType });
    const url = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.setAttribute('download', data.filename);
    if (this.openInsteadOfDownload) {
      window.open(this.appConfigService.getConfig().apiUrl + `/api/filedata/${data.id}/${data.filename}`, '_blank');
    } else {
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }
  }
}
