<mat-icon matTooltip="{{ 'Common.Filters.FilterNumber' | translate }}" [matMenuTriggerFor]="menu">filter_alt</mat-icon>

<mat-menu #menu="matMenu">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <form [formGroup]="filterForm" (click)="$event.stopPropagation();">
                    <mat-form-field>
                        <mat-label>{{ 'Common.Filters.FilterType' | translate }}</mat-label>
                        <mat-select [formControl]="filterTypeControl" [value]="filterTypeControl"
                            (selectionChange)="onFilterChange($event)" (click)="$event.stopPropagation();">
                            <mat-option *ngFor="let option of filterOptions" [value]="option.value"
                                (click)="$event.stopPropagation();">
                                {{ option.translationKey | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div>
        </div>

        <ng-container *ngIf="this.currentFilter.value !== this.numberFilterBetween; else betweenTemplate">
            <div class="row">
                <div class="col-12">
                    <form [formGroup]="singleNumberForm" (click)="$event.stopPropagation();">
                        <mat-form-field>
                            <mat-label>{{'Common.Filters.Number' | translate }} </mat-label>
                            <input type="number" matInput [formControl]="numberSingleNumberControl"
                                (click)="$event.stopPropagation();">
                        </mat-form-field>
                    </form>
                </div>
            </div>
        </ng-container>

        <ng-template #betweenTemplate>
            <div class="row">
                <div class="col-12">
                    <form [formGroup]="numberBetweenForm" (click)="$event.stopPropagation();">
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field>
                                    <mat-label>{{'Common.Filters.From' | translate }} </mat-label>
                                    <input type="number" matInput [formControl]="numberBetweenValueControl"
                                        (click)="$event.stopPropagation();">
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <mat-form-field>
                                    <mat-label>{{'Common.Filters.To' | translate }} </mat-label>
                                    <input type="number" matInput [formControl]="numberBetweenValue2Control"
                                        (click)="$event.stopPropagation();">
                                </mat-form-field>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </ng-template>

        <div class="row">
            <div class="col" style="display: flex; justify-content: flex-end;">
                <mat-icon matTooltip="{{ 'Common.Filters.Cancel' | translate }}" style="cursor: pointer;"
                    color="primary" (click)="onCancel()">
                    clear
                </mat-icon>
                <mat-icon matTooltip="{{ 'Common.Filters.RunFilter' | translate }}" style="cursor: pointer;"
                    color="primary" (click)="onSubmit($event)">
                    check
                </mat-icon>
                <mat-icon matTooltip="{{ 'Common.Filters.Clear' | translate }}" style="cursor: pointer;" color="primary"
                    *ngIf="this.currentValue | async" (click)="onClear()">
                    delete
                </mat-icon>
            </div>
        </div>
    </div>
</mat-menu>