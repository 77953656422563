import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { BaseComponent } from '../../core/components/base.component';

@Component({
  selector: 'app-auto-complete-select',
  templateUrl: './auto-complete-select.component.html',
  styleUrls: ['./auto-complete-select.component.scss']
})
export class AutoCompleteSelectComponent extends BaseComponent implements OnInit {
  @Input() itemControl!: FormControl;
  @Input() getItemName!: (item: any) => any;
  @Input() search!: (value: string) => Observable<any[] | null>;
  @Input() debounceTime: number = 300;
  @Output() selected = new EventEmitter<any | null>();
  @Input() label: string | null = null;
  @Input() error: string | null = null;
  @Input() minCharacters: number = 0;
  filteredItems!: Observable<any[] | null>;

  constructor() {
    super();
  }

  ngOnInit() {
    this.filteredItems = this.itemControl
      .valueChanges
      .unsubscribeOnDestroy(this)
      .pipe(
        debounceTime(this.debounceTime),
        switchMap(value => {
          // Don't search for the selected item
          if ((value && !(typeof value === 'string')) || ((value as string)?.length ?? 0) < this.minCharacters) {
            return of(null);
          }

          // value contains text in this case
          return this.search(value);
        })
      );
  }

  onItemSelected(event: MatAutocompleteSelectedEvent): void {
    this.selected.emit(event.option.value);
  }

  getDisplayName = (item: any) => {
    return this.getItemName(item);
  }

  onRemoveSelectedItem(): void {
    this.itemControl.setValue(null);
    this.selected.emit(null);
  }

  onFocus() {
    if (!this.itemControl.value) {
      this.itemControl.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    }
  }
}
