import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from '../../core/components/base.component';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent extends BaseComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {
  }
}
