import { NgControl, ControlValueAccessor, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit, HostBinding, Optional, Self, ElementRef, ViewChild, OnDestroy, AfterViewInit, Output, EventEmitter, Inject, OnChanges } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty, _isNumberValue } from '@angular/cdk/coercion';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { AutofillMonitor } from '@angular/cdk/text-field';
import { StringHelper } from 'src/app/core/helpers/string.helper';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';

@Component({
  host: {
    '(focusout)': 'onTouched()',
  },
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: TimePickerInputComponent
    }
  ],
  selector: 'app-time-picker-input',
  templateUrl: './time-picker-input.component.html',
  styleUrls: ['./time-picker-input.component.scss'],
})

export class TimePickerInputComponent
  implements
  AfterViewInit,
  ControlValueAccessor,
  MatFormFieldControl<string>,
  OnDestroy,
  OnInit,
  OnChanges {
  static nextId: number = 0;

  @Input() startValue: string | undefined;
  @Input() preventTimeDifference: boolean = false; // if true, the time difference between startValue and option will be hidden
  @Input() endValue: string | undefined;
  @Input() startAtZero: boolean = false;
  @Input() includeMidnight: boolean = false;
  @Input() step!: number; // size of the steps between one posisble value to the next
  @Input() availableTime!: string[]; // disable specific times

  minutes: number[] = [];
  hours: number[] = [];
  autoHours: string[] = [];
  autoMinutes: string[] = [];

  matOptions: any[] = [];

  private _disabled: boolean = false;
  private _focused: boolean = false;
  private _placeholder: string = '';
  private _required: boolean = false;
  private destroy: Subject<void> = new Subject();

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);

    if (value) {
      this.hoursControl?.disable();
      this.minutesControl?.disable();
    }
    else {
      this.hoursControl?.enable();
      this.minutesControl?.enable();
    }

    this.stateChanges.next();
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get value(): string | null {
    const n = this.parts.value;
    if (n.hours.length == 2 && n.minutes.length == 2) {
      return `${StringHelper.pad(n.hours, 2)}:${StringHelper.pad(n.minutes, 2)}`
    }

    return null;
  }
  set value(value: string | TimeModel | null) {
    let inputVal: TimeModel;
    if (typeof (value) === 'string') {
      inputVal = new TimeModel(value.substring(0, value.indexOf(':')), value.substring(value.indexOf(':') + 1));
    } else if (value && typeof (value) === 'object') {
      inputVal = value;
    } else {
      inputVal = new TimeModel();
    }

    const { hours, minutes } = inputVal;

    this.parts.setValue({ hours, minutes });
    this.stateChanges.next();
  }

  @HostBinding('attr.aria-describedby')
  describedBy: string = '';
  @HostBinding()
  id = `time-control-${++TimePickerInputComponent.nextId}`;
  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @ViewChild('hours', { read: ElementRef })
  hoursRef!: ElementRef<HTMLInputElement>;
  @ViewChild('minutes', { read: ElementRef })
  minutesRef!: ElementRef<HTMLInputElement>;

  @ViewChild('timeSelect') timeSelect!: MatSelect;

  autofilled: boolean = false;
  controlType: string = 'time';
  get empty(): boolean {
    const n: TimeModel = this.parts.value;
    return !n.hours && !n.minutes;
  }
  get errorState(): boolean {
    return (this.ngControl?.invalid && this.ngControl?.touched) == true;
  }
  get focused(): boolean {
    return this._focused;
  }
  set focused(value: boolean) {
    this._focused = value;
    this.stateChanges.next();
  }

  parts: FormGroup = new FormGroup({
    hours: new FormControl<string>(''),
    minutes: new FormControl<string>(''),
    dropdownValue: new FormControl<string>('')
  });

  stateChanges: Subject<void> = new Subject<void>();
  // check for validators to set required correctly on validators
  stateChangeSubscription = this.stateChanges
    .subscribe(_ => {
      if (this.ngControl?.control?.hasValidator(Validators.required)) {
        this._required = true;
      } else {
        this._required = false;
      }
    });

    @Output() public readonly valueChange = new EventEmitter<string>();

  constructor(
    private focusMonitor: FocusMonitor,
    private elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() @Inject(MAT_FORM_FIELD) public formField: MatFormField,
    private autofillMonitor: AutofillMonitor,
    private readonly translateService: TranslateService,
  ) {
    // Setting the value accessor directly (instead of using
    // the providers) to avoid running into a circular import.
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
  ngOnInit(): void {
    for (let i = 0; i < 60; i = i + this.step) {
      this.minutes.push(i);
      this.autoMinutes.push(`${StringHelper.pad(i, 2)}`);
    }

    for (let i = 0; i < 24; i++) {
      this.hours.push(i);
      this.autoHours.push(`${StringHelper.pad(i, 2)}`);
    }

    this.initMatOption();


  }

  ngOnChanges(): void {
    this.initMatOption();
    this.stateChanges.next();
  }


  private _filterHours(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.autoHours.filter(hour => this._normalizeValue(hour).includes(filterValue));
  }

  private _filterMinutes(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.autoMinutes.filter(minute => this._normalizeValue(minute).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  hoursFocusOut(): void {
    const value = this.hoursControl.value;
    const minutesValue = this.minutesControl.value;
    if (!_isNumberValue(minutesValue) && _isNumberValue(value)) {
      if (this.startValue) {
        const lowestMinutesString = this.startValue.substring(this.startValue.indexOf(':') + 1);

        this.minutesControl.setValue(lowestMinutesString);
      } else {
        this.minutesControl.setValue('00');
      }
    }

    let possibleHours = this._filterHours(value);

    if (possibleHours && possibleHours.length === 1 && possibleHours[0] === value) {
      this.hoursControl.setValue(possibleHours[0]);

      this.checkValues();

      return;
    }

    if (value.length > 0) {
      possibleHours = this._filterHours(value[0]);

      if (possibleHours.length > 0) {
        this.hoursControl.setValue(possibleHours[0]);

        this.checkValues();

        return;
      }
    }

    if (value.length > 1) {
      possibleHours = this._filterHours(value[1]);

      if (possibleHours.length > 0) {
        this.hoursControl.setValue(possibleHours[0]);

        this.checkValues();

        return;
      }
    }

    if (this.autoHours && this.autoHours.length > 0) {
      this.hoursControl.setValue(this.autoHours[0]);
      this.stateChanges.next();
      this.hoursFocusOut();
    }
  }

  minutesFocusOut(): void {
    const value = this.minutesControl.value;
    const hoursValue = this.hoursControl.value;
    if (!_isNumberValue(hoursValue) && _isNumberValue(value)) {
      if (this.startValue) {
        const lowestHoursString = this.startValue.substring(0, this.startValue.indexOf(':'));
        this.hoursControl.setValue(lowestHoursString);
      } else {
        this.hoursControl.setValue('00');
      }
    }

    let possibleMinutes = this._filterMinutes(value);

    if (possibleMinutes && possibleMinutes.length > 0 && possibleMinutes[0] === value) {
      this.minutesControl.setValue(possibleMinutes[0]);

      this.checkValues();

      return;
    }

    if (value.length > 0) {
      possibleMinutes = this._filterMinutes(value[0]);

      if (possibleMinutes.length > 0) {
        this.minutesControl.setValue(possibleMinutes[0]);

        this.checkValues();

        return;
      }
    }

    if (value.length > 1) {
      possibleMinutes = this._filterMinutes(value[1]);

      if (possibleMinutes.length > 0) {
        this.minutesControl.setValue(possibleMinutes[0]);

        this.checkValues();

        return;
      }
    }

    if (this.autoMinutes && this.autoMinutes.length > 0) {
      this.minutesControl.setValue(this.autoMinutes[0]);
      this.stateChanges.next();
      this.minutesFocusOut();
    }
  }

  selectValueChanged(event: MatSelectChange): void {
    setTimeout(() => {
      const hoursValue = event.value.substring(0, 2);
      const minValue = event.value.substring(3);
      this.hoursControl.setValue(hoursValue);
      this.minutesControl.setValue(minValue);

      this.valueChange.emit(event.value);
    });
  }

  inputValueChanged(val: any): void {
    setTimeout(() => {
      this.dropdownValueControl.setValue(val);

      this.valueChange.emit(val);
    });
  }

  checkValues(): void {
    const currentHours = parseInt(this.hoursControl.value);
    const currentMinutes = parseInt(this.minutesControl.value);

    if (this.startValue) {
      const lowestHoursString = this.startValue.substring(0, this.startValue.indexOf(':'));
      const lowestHours = parseInt(lowestHoursString);
      const lowestMinutesString = this.startValue.substring(this.startValue.indexOf(':') + 1);
      const lowestMinutes = parseInt(lowestMinutesString);

      if (currentHours < lowestHours) {
        this.hoursControl.setValue(lowestHoursString);
        this.minutesControl.setValue(lowestMinutesString);
      } else if (currentHours === lowestHours && currentMinutes < lowestMinutes) {
        this.minutesControl.setValue(lowestMinutesString);
      }
    }

    if (this.endValue) {
      const highestHoursString = this.endValue.substring(0, this.endValue.indexOf(':'));
      const highestHours = parseInt(highestHoursString);
      const highestMinutesString = this.endValue.substring(this.endValue.indexOf(':') + 1);
      const highestMinutes = parseInt(highestMinutesString);

      if (currentHours > highestHours) {
        this.hoursControl.setValue(highestHoursString);
        this.minutesControl.setValue(highestMinutesString);
      } else if (currentHours === highestHours && currentMinutes > highestHours) {
        this.minutesControl.setValue(highestMinutesString);
      }
    }

    this.inputValueChanged(this.value)
  }

  ngAfterViewInit(): void {
    this.focusMonitor.monitor(this.elementRef.nativeElement, true)
      .subscribe(focusOrigin => {
        this.focused = !!focusOrigin;
      });
    combineLatest(
      this.observeAutofill(this.hoursRef),
      this.observeAutofill(this.minutesRef),
    ).pipe(
      map(autofills => autofills.some(autofilled => autofilled)),
      takeUntil(this.destroy),
    ).subscribe(autofilled => this.autofilled = autofilled);
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
    this.autofillMonitor.stopMonitoring(this.hoursRef);
    this.autofillMonitor.stopMonitoring(this.minutesRef);
    this.stateChangeSubscription.unsubscribe();
  }

  onContainerClick(event: MouseEvent): void {
    // check if container gets clicked and not the button and set input as focus
    if ((event.target as Element).tagName.toLowerCase() !== 'input' && (event.target as Element).parentElement?.tagName.toLowerCase() !== 'button') {
      this.focusMonitor.focusVia(this.hoursRef.nativeElement, 'mouse');
    }
  }

  onTouched(): void { }

  registerOnChange(onChange: (value: string | null) => void): void {
    this.parts.valueChanges.pipe(
      takeUntil(this.destroy),
      map(val => { return `${StringHelper.pad(val.hours, 2)}:${StringHelper.pad(val.minutes, 2)}` })
    ).subscribe(onChange);
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  setDisabledState(shouldDisable: boolean): void {
    if (shouldDisable) {
      this.parts.disable();
    } else {
      this.parts.enable();
    }

    this.disabled = shouldDisable;
  }

  writeValue(value: string | null): void {
    let inputVal: TimeModel;
    if (typeof (value) === 'string') {
      inputVal = new TimeModel(value.substring(0, value.indexOf(':')), value.substring(value.indexOf(':') + 1), value);
    } else if (value === null) {
      inputVal = new TimeModel();
    } else if (typeof (value) === 'object') {
      inputVal = value;
    } else {
      inputVal = new TimeModel();
    }

    this.parts.setValue(inputVal, { emitEvent: false });
  }

  private observeAutofill(ref: ElementRef): Observable<boolean> {
    return this.autofillMonitor.monitor(ref)
      .pipe(map(event => event.isAutofilled))
      .pipe(startWith(false));
  }

  menuButtonClicked(): void {
    this.timeSelect.open();
  }

  initMatOption(): void {
    this.matOptions = [];

    for (const hour of this.hours) {
      for (const minute of this.minutes) {
        const object: any = {
          value: '',
          text: ''
        };

        object.value = `${StringHelper.pad(hour, 2)}:${StringHelper.pad(minute, 2)}`;

        if (!this.startValue) {
          object.text = `${StringHelper.pad(hour, 2)}:${StringHelper.pad(minute, 2)}`;
          this.matOptions.push(object);
        } else {
          const startHour = parseInt(this.startValue.substring(0, 2), 10);
          let hourDiff = hour - startHour;
          const startMinute = parseInt(this.startValue.substring(3), 10);
          let minuteDiff = minute - startMinute;

          if (minuteDiff < 0) {
            hourDiff--;
            minuteDiff = 60 + minuteDiff;
          }

          if (hourDiff >= 0) {
            let minuteDecimal = minuteDiff;
            let hourMin = this.translateService.instant('Common.Minutes');
            let timeSpanString = `${StringHelper.pad(minuteDiff, 2)} ${hourMin}`;
            if (hourDiff > 0) {
              minuteDecimal = minuteDiff / 60;
              hourMin = this.translateService.instant('Common.Hours');
              if (hourDiff === 1) {
                hourMin = this.translateService.instant('Common.Hour');
              }
              let minuteTimeSpan = `${minuteDiff.toString()} ${this.translateService.instant('Common.Minutes')}`;
              if (minuteDiff === 0) {
                minuteTimeSpan = '';
              }
              timeSpanString = `${hourDiff} ${hourMin} ${minuteTimeSpan}`;
            }

            if (hourDiff !== 0 || minuteDiff !== 0 || (hourDiff === 0 && minuteDiff === 0 && this.startAtZero)) {
              if (!this.preventTimeDifference) {
                object.text = `${StringHelper.pad(hour, 2)}:${StringHelper.pad(minute, 2)} (${timeSpanString})`;
              } else {
                object.text = `${StringHelper.pad(hour, 2)}:${StringHelper.pad(minute, 2)}`;
              }
              this.matOptions.push(object);
            }
          }
        }

        // if endvalue is set, cancel if it's reached
        if (this.endValue) {
          const endHour = parseInt(this.endValue.substring(0, 2), 10);
          const endMinute = parseInt(this.endValue.substring(3), 10);

          if (hour >= endHour && minute >= endMinute) {
            return;
          }
        }
      }
    }

    if (this.includeMidnight && this.startValue) {
      const startHour = parseInt(this.startValue.substring(0, 2), 10);
      let hourDiff = 24 - startHour;
      const startMinute = parseInt(this.startValue.substring(3), 10);
      let minuteDiff = 0 - startMinute;

      if (minuteDiff < 0) {
        hourDiff--;
        minuteDiff = 60 + minuteDiff;
      }

      let text = `24:00 (${hourDiff} ${this.translateService.instant('Common.Hours')})`;
      if (minuteDiff !== 0 && hourDiff > 1) {
        text = `24:00 (${hourDiff} ${this.translateService.instant('Common.Hours')} ${minuteDiff} ${this.translateService.instant('Common.Minutes')})`;
      } else if (minuteDiff === 0 && hourDiff === 1) {
        text = `24:00 (${hourDiff} ${this.translateService.instant('Common.Hour')})`;
      } else if (hourDiff === 1 && minuteDiff > 0) {
        text = `24:00 (${hourDiff} ${this.translateService.instant('Common.Hour')} ${minuteDiff} ${this.translateService.instant('Common.Minutes')})`;
      } else if (minuteDiff > 0) {
        text = `24:00 (${minuteDiff} ${this.translateService.instant('Common.Minutes')})`;
      }

      if (this.preventTimeDifference) {
        text = '24:00';
      }

      this.matOptions.push({ value: '24:00', text });
    } else if (this.includeMidnight) {
      this.matOptions.push({ value: '24:00', text: '24:00' });
    }

    if (this.availableTime) {
      this.matOptions = this.matOptions.filter(x => this.availableTime.includes(x.value));
    }
  }

  get minutesControl(): FormControl {
    return this.parts.get('minutes') as FormControl;
  }

  get hoursControl(): FormControl {
    return this.parts.get('hours') as FormControl;
  }

  get dropdownValueControl(): FormControl {
    return this.parts.get('dropdownValue') as FormControl;
  }
}

class TimeModel {
  hours: string;
  minutes: string;
  dropdownValue: string;

  constructor(hours: string = '', minutes: string = '', dropdownValue: string = '') {
    this.hours = hours;
    this.minutes = minutes;
    this.dropdownValue = dropdownValue;
  }
}
