import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss']
})
export class ErrorMessageComponent {
  constructor(private matSnackBarRef: MatSnackBarRef<ErrorMessageComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public message: string) {
  }

  dismiss() {
    this.matSnackBarRef.dismiss();
  }

  reload() {
    window.location.reload();
  }
}
