import * as rg4js from 'raygun4js';
import { ErrorHandler, Injectable } from '@angular/core';
import { AppConfigService } from '../services/app-config.service';
import { BaseAuthenticationService } from '../services/base-authentication.service';

@Injectable()
 // this class re-implements part of the AuthenticationService
 // because AuthenticationService does not properly resolve in the contructor
export class GlobalErrorHandler implements ErrorHandler {
  private configService: AppConfigService;
  private apiKey?: string;

  constructor(config: AppConfigService) {
    this.configService = config;
  }

  handleError(error: any): void {
    console.log(error);

    this.checkAndSetupRaygun();

    if (this.apiKey) {
      this.setRaygunUserInfo();
      this.sendErrorToRaygun(error);
    }

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }

    // throw error;
  }

  private checkAndSetupRaygun() : void {
    if (!this.apiKey) {
      const config = this.configService.getConfig();

      if (config?.raygunApiKey) {
        this.apiKey = config.raygunApiKey;
        rg4js('apiKey', this.apiKey);
        rg4js('enableCrashReporting', true);
        rg4js('withTags', 'angular');
        rg4js('boot'); //force reload after configuration
      }
    }
  }

  private getUserInfo() {
    const privateItem = BaseAuthenticationService.getPrivateEmail();
    const publicItem = BaseAuthenticationService.getPublicEmail();

    let item: string | null = null;

    if (privateItem && publicItem) {
      item = `private: ${privateItem} public: ${publicItem}`;
    } else if (privateItem) {
      item = privateItem;
    } else if (publicItem) {
      item = publicItem;
    }

    return {
      identifier: (item ? item : 'n/a'),
      isAnonymous: (item === null)
    };
  }

  private setRaygunUserInfo() {
    rg4js('setUser', this.getUserInfo());
  }

  private sendErrorToRaygun(error: string): void {
    rg4js('send', {
      error: error,
    });
  }

}
