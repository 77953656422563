import { TranslateService } from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

/*export class TranslatedPaginatorIntl {
    constructor(private readonly translate: TranslateService) {}

    getPaginatorIntl(): MatPaginatorIntl {
        const paginatorIntl = new MatPaginatorIntl();
        
        paginatorIntl.itemsPerPageLabel = this.translate.instant('Common.Paginator.ItemsPerPage');
        paginatorIntl.nextPageLabel = this.translate.instant('Common.Paginator.NextPageLabel');
        paginatorIntl.previousPageLabel = this.translate.instant('Common.Paginator.PreviousPageLabel');
        paginatorIntl.firstPageLabel = this.translate.instant('Common.Paginator.FirstPageLabel');
        paginatorIntl.lastPageLabel = this.translate.instant('Common.Paginator.LastPageLabel');
        paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);
        return paginatorIntl;
    }

    private getRangeLabel(page: number, pageSize: number, length: number): string {
        if (length === 0 || pageSize === 0) {
            return this.translate.instant('Common.Paginator.RangePageLabel1', { length });
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return this.translate.instant('Common.Paginator.RangePageLabel2', { startIndex: startIndex + 1, endIndex, length });
    }
}*/

const ITEMS_PER_PAGE = 'Common.Paginator.ItemsPerPage';
const NEXT_PAGE = 'Common.Paginator.NextPageLabel';
const PREV_PAGE = 'Common.Paginator.PreviousPageLabel';
const FIRST_PAGE = 'Common.Paginator.FirstPageLabel';
const LAST_PAGE = 'Common.Paginator.LastPageLabel';

@Injectable()
export class TranslatedPaginatorIntl extends MatPaginatorIntl {
  public constructor(private translate: TranslateService) {
    super();

    this.translate.onLangChange
    //.pipe(takeUntil(...))
    .subscribe((e: Event) => {
      this.getAndInitTranslations();
    });

    this.getAndInitTranslations();
  }

  public override getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
        return this.translate.instant('Common.Paginator.RangePageLabel1', { length });
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return this.translate.instant('Common.Paginator.RangePageLabel2', { startIndex: startIndex + 1, endIndex, length });
  };

  public getAndInitTranslations(): void {
    this.translate.get([
      ITEMS_PER_PAGE,
      NEXT_PAGE,
      PREV_PAGE,
      FIRST_PAGE,
      LAST_PAGE,
    ])
    .subscribe((translation: any) => {
      this.itemsPerPageLabel = translation[ITEMS_PER_PAGE];
      this.nextPageLabel = translation[NEXT_PAGE];
      this.previousPageLabel = translation[PREV_PAGE];
      this.firstPageLabel = translation[FIRST_PAGE];
      this.lastPageLabel = translation[LAST_PAGE];

      this.changes.next();
    });
  }
}
