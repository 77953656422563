import { Validators, UntypedFormBuilder, AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { BaseFilterComponent } from '../base-filter.component';
import { MatSelectChange } from '@angular/material/select';
import { EnumFilterType } from 'src/app/core/models/api-models';
import { FilterType } from 'src/app/core/common/filter-type';
import { Filter, IdNameModel } from 'src/app/core/models/filter-paging.model';

@Component({
  selector: 'app-enum-filter',
  templateUrl: './enum-filter.component.html',
  styleUrls: ['./enum-filter.component.scss']
})
export class EnumFilterComponent extends BaseFilterComponent<EnumFilterType> implements OnInit {

  @Input() options: IdNameModel[] = [];
  @Input() filterSelectText: string | undefined;

  // StringFilterType
  enumFilterType = EnumFilterType;

  enumContainsFilter = EnumFilterType.Contains;

  filterForm = new FormGroup({
    filterType: new FormControl<EnumFilterType>(EnumFilterType.Equals, { validators: [Validators.required], nonNullable: true }),
  });

  enumForm = new FormGroup({
    value: new FormControl<string>('', { validators: [Validators.required] }),
  });

  multipleEnumForm = new FormGroup({
    value: new FormControl<string>('', { validators: [Validators.required] }),
  });

  filterOptions = this.getFilterOptions();
  currentFilter = new BehaviorSubject<EnumFilterType>(EnumFilterType.Equals);
  filterType: FilterType = FilterType.Enum;

  constructor() {
    super();
   }

  getFilterOptions(): { value: number, translationKey: string }[] {
    const options: { value: number, translationKey: string }[] = [];

    Object.keys(EnumFilterType).filter((enumVal) => !isNaN(Number(enumVal))).forEach((enumVal: any) => {
      options.push({
        value: parseInt(enumVal, 10),
        translationKey: `Common.Filters.EnumFilters.${EnumFilterType[enumVal]}`
      });
    });

    return options;
  }

  onFilterChange($event: MatSelectChange): void {
    this.currentFilter.next($event.value as EnumFilterType);
  }

  ngOnInit(): void {
    if (this.initialValue) {
      this.currentValue.next(this.initialValue);
    }
  }

  clearFilter(): void {
    this.currentValue.next(null);
    this.activeFilterField = this.currentValue.value !== null;
    this.enumForm.reset();
    this.multipleEnumForm.reset();
  }

  initValue(value: Filter<EnumFilterType>): void {
    this.enumForm.markAllAsTouched();
    this.currentFilter.next(value.filterType);

    if (value.filterType === 0) {
      this.enumControl.setValue(value.values[0]);
    } else if (value.filterType === 1) {
      this.multipleEnumControl.setValue(value.values);
    }

    this.filterTypeControl.setValue(value.filterType);
    this.currentValue.next(value);
    this.activeFilterField = this.currentValue.value !== null;
  }

  onSubmit($event: any): void {
    if (this.currentFilter.value !== EnumFilterType.Contains) {
      // Trigger form validation for untouched fields
      this.enumForm.markAllAsTouched();

      if (this.enumForm.valid) {
        const model: Filter<EnumFilterType> = {
          filterType: this.currentFilter.value,
          values: [this.enumControl.value, null] as any[],
          name: this.propertyName
        };

        this.currentValue.next(model);
        this.filterChanged.emit({ filter: this.currentValue.value, filterType: this.filterType, propertyName: this.propertyName });
        this.activeFilterField = this.currentValue.value !== null;
      } else {
        $event.stopPropagation();
      }
    } else {
      // Trigger form validation for untouched fields
      this.multipleEnumForm.markAllAsTouched();

      if (this.multipleEnumForm.valid) {
        const model: Filter<EnumFilterType> = {
          filterType: this.currentFilter.value,
          values: this.multipleEnumControl.value as any[],
          name: this.propertyName
        };

        this.currentValue.next(model);
        this.filterChanged.emit({ filter: this.currentValue.value, filterType: this.filterType, propertyName: this.propertyName });
        this.activeFilterField = this.currentValue.value !== null;
      } else {
        $event.stopPropagation();
      }
    }
  }

  onCancel(): void {
  }

  get enumControl(): FormControl {
    return this.enumForm.get('value')! as FormControl;
  }

  get multipleEnumControl(): FormControl {
    return this.multipleEnumForm.get('value')! as FormControl;
  }

  get filterTypeControl(): FormControl {
    return this.filterForm.get('filterType')! as FormControl;
  }

}
