export default class LocalStorageHelper {
  static setItemFromObject<T>(key: string, item: T): void {
    localStorage.setItem(key, JSON.stringify(item));
  }

  static getItemFromObject<T>(key: string): T | null {
    try {
      return JSON.parse(localStorage.getItem(key)!) as T;
    }
    catch {
      this.clear(key);
    }

    return null;
  }

  public static clear(key: string): void {
    if (!key) {
      return;
    }

    localStorage.removeItem(key);
  }
}
