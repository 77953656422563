<button [className]="cssClass" mat-button color="primary" [matMenuTriggerFor]="languageMenu" matTooltip="{{ 'Common.Language' | translate }}">
  <mat-icon class="align-middle">language</mat-icon>
  <span class="d-none d-md-inline align-middle">{{ (currentLanguage$ | async)?.name }}</span>
</button>

<mat-menu #languageMenu>
  <div >
    <ng-container *ngFor="let language of languages">
      <button mat-menu-item type="button" (click)="changeLanguage(language.languageCode)"
        [ngClass]="{ active: (currentLanguage$ | async)?.languageCode == language.languageCode }">
        {{ language.name }}
      </button>
    </ng-container>
  </div>
</mat-menu>
