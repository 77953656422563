import { AbstractControl, ValidatorFn } from '@angular/forms';

export function largerThanValueValidator(value: () => any, conversionLeft?: (value: any) => any, conversionRight?: (value: any) => any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let value1 = control.value;
    let value2 = value();

    if(conversionLeft) {
      value1 = conversionLeft(value1);

      if(!conversionRight) {
        value2 = conversionLeft(value2);
      }
    }

    if(conversionRight) {
      value2 = conversionRight(value2);
    }

    if (value1 <= value2) {
      return { largerThan: true };
    }

    return null;
  };
}
