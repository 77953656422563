<mat-icon matTooltip="{{ 'Common.Filters.FilterText' | translate }}" [matMenuTriggerFor]="menu">filter_alt</mat-icon>

<mat-menu #menu="matMenu">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <form [formGroup]="filterForm" (click)="$event.stopPropagation();">
                    <mat-form-field>
                        <mat-label>{{ 'Common.Filters.FilterType' | translate }}</mat-label>
                        <mat-select [formControl]="filterTypeControl" [value]="filterTypeControl"
                            (selectionChange)="onFilterChange($event)">
                            <mat-option *ngFor="let option of filterOptions" [value]="option.value">
                                {{ option.translationKey | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <form [formGroup]="stringForm" (click)="$event.stopPropagation();">
                    <mat-form-field>
                        <mat-label>{{'Common.Filters.Text' | translate }} </mat-label>
                        <input matInput [formControl]="stringControl">
                    </mat-form-field>
                </form>
            </div>
        </div>

        <div class="row">
            <div class="col" style="display: flex; justify-content: flex-end;">
                <mat-icon matTooltip="{{ 'Common.Filters.Cancel' | translate }}" style="cursor: pointer;"
                    color="primary" (click)="onCancel()">
                    clear
                </mat-icon>
                <mat-icon matTooltip="{{ 'Common.Filters.RunFilter' | translate }}" style="cursor: pointer;"
                    color="primary" (click)="onSubmit($event)">
                    check
                </mat-icon>
                <mat-icon matTooltip="{{ 'Common.Filters.Clear' | translate }}" style="cursor: pointer;" color="primary"
                    *ngIf="this.currentValue | async" (click)="onClear()">
                    delete
                </mat-icon>
            </div>
        </div>
    </div>
</mat-menu>