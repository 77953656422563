import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { mergeMap, map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessage } from '../models/error-message.model';
import { ErrorMessageComponent } from 'src/app/shared/messages/error-message/error-message.component';
import { NotificationMessageComponent } from 'src/app/shared/messages/notification-message/notification-message.component';
import { SuccessMessageComponent } from 'src/app/shared/messages/success-message/success-message.component';
import { DirtyComponent } from '../components/dirty.component';
import { ConfirmMessageComponent } from 'src/app/shared/messages/confirm-message/confirm-message.component';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor(private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService) {
  }

  openError(response: HttpErrorResponse): MatSnackBarRef<ErrorMessageComponent> {
    const error: ErrorMessage = {
      detail: ''
    };

    if (response.error) {
      if (response.error.message) {
        error.detail = response.error.message;
      } else if (response.error.error) {
        // Handle RXJS errors
        error.detail = response.error.error.message;
      } else if (response.message) {
        // Handle RXJS errors
        error.detail = response.message;
      } else {
        error.detail = response.error;
      }
    } else {
      error.detail = response.message;
    }

    return this.openErrorMessage(error.detail);
  }

  openErrorLabel(label: string, params?: object): MatSnackBarRef<ErrorMessageComponent> {
    const errorMessage = this.translateService.instant(label, params);
    return this.openErrorMessage(errorMessage);
  }

  openErrorMessage(message: string): MatSnackBarRef<ErrorMessageComponent> {
    return this.snackBar.openFromComponent(ErrorMessageComponent, {
      horizontalPosition: 'end',
      data: message
    });
  }

  openUnexpectedErrorMessage(): MatSnackBarRef<ErrorMessageComponent> {
    return this.openErrorLabel('Common.Messages.UnexpectedError');
  }

  dismiss(): void {
    this.snackBar._openedSnackBarRef?.dismiss();
  }

  openNotification(texts: [string, object][]): MatSnackBarRef<NotificationMessageComponent> {
    return this.snackBar.openFromComponent(NotificationMessageComponent, {
      horizontalPosition: 'end',
      data: texts
    });
  }

  closeStatusSnackBarMessages(): void {
    if (this.snackBar._openedSnackBarRef) {
      if (this.snackBar._openedSnackBarRef.containerInstance.snackBarConfig.duration !== 0) {
        this.snackBar.dismiss();
      }
    }
  }

  closeAllSnackBarMessages(): void {
    if (this.snackBar._openedSnackBarRef) {
      this.snackBar.dismiss();
    }
  }

  openSuccess(message: string, duration: number = 3000): MatSnackBarRef<SuccessMessageComponent> {
    return this.snackBar.openFromComponent(SuccessMessageComponent, {
      horizontalPosition: 'end',
      data: message,
      duration: duration
    });
  }

  openSuccessLabel(label: string, params?: object, duration?: number): Observable<MatSnackBarRef<SuccessMessageComponent>> {
    return this.translateService.get(label, params).pipe(map(value => {
      return this.openSuccess(value, duration);
    }));
  }

  openSaveSuccess(): MatSnackBarRef<SuccessMessageComponent> {
    return this.openSuccess('Common.Messages.SaveSuccess');
  }

  openDeleteSuccess(): MatSnackBarRef<SuccessMessageComponent> {
    return this.openSuccess('Common.Messages.DeleteSuccess');
  }

  openConfirmLabel(message: string, primaryButtonLabel: string = 'Common.Ok', secondaryButtonLabel: string = 'Common.Cancel'): MatSnackBarRef<ConfirmMessageComponent> {
    return this.snackBar.openFromComponent(ConfirmMessageComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'show-large-text',
      data: {
        message: this.translateService.instant(message),
        primaryButtonLabel: this.translateService.instant(primaryButtonLabel),
        secondaryButtonLabel: secondaryButtonLabel ? this.translateService.instant(secondaryButtonLabel) : null,
      }
    });
  }

  openConfirm(message: string, primaryButtonLabel: string | null = null, secondaryButtonLabel: string | null = null): MatSnackBarRef<ConfirmMessageComponent> {
    let primaryBtnLabel = this.translateService.instant('Common.Ok');
    let secondaryBtnLabel = this.translateService.instant('Common.Cancel');

    if (primaryButtonLabel != null) {
      primaryBtnLabel = primaryButtonLabel;
    }

    if (secondaryButtonLabel != null) {
      secondaryBtnLabel = secondaryButtonLabel;
    }

    return this.snackBar.openFromComponent(ConfirmMessageComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'show-large-text',
      data: {
        message: message,
        primaryButtonLabel: primaryBtnLabel,
        secondaryButtonLabel: secondaryBtnLabel,
      }
    });
  }

  openDirty(component: DirtyComponent): Observable<boolean> {
    return component.isDirty.pipe(
      mergeMap(isDirty => { // TODO: Shouldn't this be map?
        if (isDirty) {
          const snackBarRef = this.openConfirm(this.translateService.instant('Common.Messages.CancelConfirmation'));

          return snackBarRef
            .afterDismissed().pipe(map(() => {
              return snackBarRef.instance.isConfirmed;
            }));
        }

        return of(true);
      }));
  }
}
