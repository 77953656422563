import { AbstractControl, ValidatorFn } from '@angular/forms';

export function smallerThanValidator(smallerThanControl: () => AbstractControl): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    // Do not check if the formGroup has not been initialized yet
    if (!control.parent) {
      return null;
    }

    const otherControl = smallerThanControl();

    if (control.value && otherControl.value && control.value > otherControl.value) {
      return { smallerThan: true };
    }

    return null;
  };
}
