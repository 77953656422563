import { Component, ElementRef, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DirtyDialogComponent } from 'src/app/core/components/dirty-dialog.component';
import { CreateBookingModel, InteriorModel, MeetingSerieRoomResult } from 'src/app/core/models/api-models';
import { MessageService } from 'src/app/core/services/message.service';

@Component({
  selector: 'app-meeting-serie-room-search',
  templateUrl: './meeting-serie-room-search.component.html',
  styleUrls: ['./meeting-serie-room-search.component.scss']
})
export class MeetingSerieRoomSearchComponent extends DirtyDialogComponent implements OnInit {
  dataSource: MatTableDataSource<MeetingSerieRoomResult> | null = null;
  displayedColumns: string[] = ['name', 'availableCount', 'seats', 'floor', 'building', 'interiors', 'location', 'select'];
  locationControls = new Map<string, FormControl<string | null>>();
  
  nameAsc: boolean = true;
  seatsAsc: boolean = true;

  constructor(protected readonly element: ElementRef,
    protected override readonly messageService: MessageService,
    protected override readonly matDialogRef: MatDialogRef<MeetingSerieRoomSearchComponent>,
    @Inject(MAT_DIALOG_DATA) data: MeetingSerieRoomResult[] | null) {
    super(messageService, matDialogRef);

    this.dataSource = new MatTableDataSource<MeetingSerieRoomResult>();
    this.dataSource.data = data!;
  }

  override ngOnInit() {
    super.ngOnInit();

    this.dataSource?.data.forEach(item => {
      if (item.requiresLocation) {
        this.locationControls.set(item.roomId, new FormControl<string | null>(null, Validators.required));
      }
    });
  }

  onSelect(item: MeetingSerieRoomResult): void {
    const locationControl = this.locationControls.get(item.roomId);

    if (item.requiresLocation && locationControl) {
      locationControl.markAsTouched();

      if (!locationControl.valid) {
        return;
      }
    }

    this.matDialogRef.close({ roomId: item.roomId, roomLocation: locationControl?.value } as CreateBookingModel);
  }

  
  orderByName(): void {
    if (this.nameAsc) {
      let sortedList = this.dataSource?.data.sort((a, b) => b.roomName.localeCompare(a.roomName));
      this.dataSource!.data = sortedList!;
      this.seatsAsc = true;
      this.nameAsc = false;
    } else {
      let sortedList = this.dataSource?.data.sort((a, b) => a.roomName.localeCompare(b.roomName));
      this.dataSource!.data = sortedList!;
      this.seatsAsc = true;
      this.nameAsc = true;
    }
  }

  orderBySeats(): void {
    if (this.seatsAsc) {
      let sortedList = this.dataSource?.data.sort((a, b) => (a.seats > b.seats) ? -1 : 1);
      this.dataSource!.data = sortedList!;
      this.nameAsc = true;
      this.seatsAsc = false;
    } else {
      let sortedList = this.dataSource?.data.sort((a, b) => (a.seats < b.seats) ? -1 : 1);
      this.dataSource!.data = sortedList!;
      this.nameAsc = true;
      this.seatsAsc = true;
    }
  }

  getInteriorString(interiors: InteriorModel[]): string {
    return interiors.map(i => i.name).join(', ');
  }
}
