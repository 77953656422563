<mat-form-field class="w-100">
  <mat-label *ngIf="!this.label">{{ 'Common.EmailChiplist.EmailAddresses' | translate }}</mat-label>
  <mat-label *ngIf="this.label">{{ label | translate }}</mat-label>
  <mat-chip-grid #chipList multiple>
    <mat-chip-row *ngFor="let emailAddress of emailAddresses" [value]="emailAddress"
      (removed)="removeEmailAddress(emailAddress)">
      {{ emailAddress }}
      <button class="remove-button" matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input placeholder="{{ 'Common.EmailChiplist.EnterEmail' | translate }}" [matChipInputFor]="chipList" [matChipInputAddOnBlur]="true"
      [formControl]="emailAddressControl" (matChipInputTokenEnd)="addEmailAddressFromInput($event)">
  </mat-chip-grid>
  <mat-error *ngIf="chipList.errorState">
    {{ 'Common.Validation.EmailPattern' | translate }}
  </mat-error>
</mat-form-field>