import { Observable } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PrivateAuthenticationService } from '../services/private-authentication.service';
import { PublicAuthenticationService } from '../services/public-authentication.service';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private readonly privateAuthenticationService: PrivateAuthenticationService,
    private readonly publicAuthenticationService: PublicAuthenticationService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const isPrivate = this.document.URL.substring(this.document.baseURI.length).startsWith('private');

    let token: string | null = null;

    if (isPrivate) {
      token = this.privateAuthenticationService.getToken();
    }
    else {
      token = this.publicAuthenticationService.getToken();
    }

    let copiedReq = request.clone({
      withCredentials: true // TODO: Do we need to send cookies?
    });

    if (token) {
      copiedReq = this.addToken(copiedReq, token);
    }

    copiedReq.headers.append('crossDomain', 'true');

    // Handle response
    return next.handle(copiedReq);
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}
