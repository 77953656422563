<mat-icon matTooltip="{{ 'Common.Filters.FilterBool' | translate }}" [matMenuTriggerFor]="menu">filter_alt</mat-icon>
<mat-menu #menu="matMenu">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <form [formGroup]="boolForm" (click)="$event.stopPropagation();">
                    <mat-form-field>
                        <mat-label *ngIf="filterSelectText">{{ filterSelectText | translate }}</mat-label>
                        <mat-label *ngIf="!filterSelectText">{{ 'Common.Filters.ChooseValue' | translate }}
                        </mat-label>
                        <mat-select [formControl]="boolControl" [value]="boolControl">
                            <mat-option [value]="true">
                                {{ (useLabels ? labelTrue : 'Common.Filters.Yes') | translate }}</mat-option>
                            <mat-option [value]="false">
                                {{ (useLabels ? labelFalse : 'Common.Filters.No') | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col" style="display: flex; justify-content: flex-end;">
                <mat-icon matTooltip="{{ 'Common.Filters.Cancel' | translate }}" style="cursor: pointer;"
                    color="primary" (click)="onCancel()">
                    clear
                </mat-icon>
                <mat-icon matTooltip="{{ 'Common.Filters.RunFilter' | translate }}" style="cursor: pointer;"
                    color="primary" (click)="onSubmit($event)">
                    check
                </mat-icon>
                <mat-icon matTooltip="{{ 'Common.Filters.Clear' | translate }}" style="cursor: pointer;" color="primary"
                    *ngIf="this.currentValue | async" (click)="onClear()">
                    delete
                </mat-icon>
            </div>
        </div>
    </div>
</mat-menu>