import { FormControl } from '@angular/forms';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import CustomValidators from '../../core/validators/custom-validators.validator';
import { TranslateService } from '@ngx-translate/core';
import { MatChipGrid, MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-email-chiplist',
  templateUrl: './email-chiplist.component.html',
  styleUrls: ['./email-chiplist.component.scss']
})
export class EmailChiplistComponent implements OnInit {
  @Input() initialValue: string[] = [];
  @Input() label: string | null = null;

  @ViewChild("chipList") chipList!: MatChipGrid;

  emailAddressesSet: Set<string> = new Set();

  emailAddressControl: FormControl<string | null> = new FormControl(null, { validators: CustomValidators.email });

  constructor(private readonly translateService: TranslateService) {
  }

  ngOnInit(): void {
    if (this.initialValue.length > 0) {
      this.initialValue.forEach(emailAddress => this.emailAddressesSet.add(emailAddress));
    }

    this.emailAddressControl.statusChanges.subscribe(controlState => {
      if (controlState === 'INVALID') {
        this.chipList.errorState = true;
      } else {
        this.chipList.errorState = false;
      }
    });
  }

  @Input()
  get emailAddresses(): string[] {
    return Array.from(this.emailAddressesSet);
  }
  set emailAddresses(newEmailAddresses: string[]) {
    this.emailAddressesSet.clear();

    if (newEmailAddresses?.length) {
      newEmailAddresses.forEach(emailAddress => this.emailAddressesSet.add(emailAddress));
    }
  }

  protected removeEmailAddress(emailAddress: string): void {
    this.emailAddressesSet.delete(emailAddress);
  }

  protected addEmailAddressFromInput(event: MatChipInputEvent) {
    if (event.value && this.emailAddressControl.valid) {
      this.emailAddressesSet.add(event.value);
      event.chipInput!.clear();
    }
  }

}
