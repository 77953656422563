import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { loadCldr, L10n } from '@syncfusion/ej2-base';

declare var require: any;

loadCldr(
  require('cldr-data/supplemental/numberingSystems.json'),
  require('cldr-data/main/de-CH/ca-gregorian.json'),
  require('cldr-data/main/de-CH/numbers.json'),
  require('cldr-data/main/de-CH/timeZoneNames.json'),
  require('cldr-data/main/fr-CH/ca-gregorian.json'),
  require('cldr-data/main/fr-CH/numbers.json'),
  require('cldr-data/main/fr-CH/timeZoneNames.json'),
  require('cldr-data/main/it-CH/ca-gregorian.json'),
  require('cldr-data/main/it-CH/numbers.json'),
  require('cldr-data/main/it-CH/timeZoneNames.json'),
  require('cldr-data/main/en-GB/ca-gregorian.json'),
  require('cldr-data/main/en-GB/numbers.json'),
  require('cldr-data/main/en-GB/timeZoneNames.json'));

// See https://github.com/syncfusion/ej2-locale/blob/master/src/de.json
L10n.load({
  'de-CH': {
    'schedule': {
      'day': 'Tag',
      'week': 'Woche',
      'workWeek': 'Arbeitswoche',
      'month': 'Monat',
      'year': 'Jahr',
      'agenda': 'Agenda',
      'weekAgenda': 'Agenda der Woche',
      'workWeekAgenda': 'Agenda der Arbeitswoche',
      'monthAgenda': 'Agenda des Monats',
      'today': 'Heute',
      'noEvents': 'Keine Termine',
      'emptyContainer': 'An diesem Tag sind keine Termine geplant.',
      'allDay': 'Ganztägig',
      'start': 'Start',
      'end': 'Ende',
      'more': 'mehr',
      'close': 'Schliessen',
      'cancel': 'Abbrechen',
      'noTitle': '(Kein Titel)',
      'delete': 'Löschen',
      'deleteEvent': 'Dieser Termin',
      'deleteMultipleEvent': 'Mehrere Termine löschen',
      'selectedItems': 'Items selected',
      'deleteSeries': 'Ganze Serie',
      'edit': 'Editieren',
      'editSeries': 'Ganze Serie',
      'editEvent': 'Termin editieren',
      'createEvent': 'Erstellen',
      'subject': 'Subjekt',
      'addTitle': 'Titel hinzufügen',
      'moreDetails': 'Mehr Details',
      'save': 'Speichern',
      'editContent': 'Wie würden Sie den Termin in der Serie gerne ändern?',
      'deleteContent': 'Sind Sie sicher, dass Sie dieses Ereignis löschen möchten?',
      'deleteMultipleContent': 'Sind Sie sicher, dass Sie die ausgewählten Ereignisse löschen möchten?',
      'newEvent': 'Neuer Termin',
      'title': 'Titel',
      'location': 'Standort',
      'description': 'Beschreibung',
      'timezone': 'Zeitzone',
      'startTimezone': 'Start Zeitzone',
      'endTimezone': 'End Zeitzone',
      'repeat': 'Wiederholen',
      'saveButton': 'Speichern',
      'cancelButton': 'Abbrechen',
      'deleteButton': 'Löschen',
      'recurrence': 'Wiederholung',
      'wrongPattern': 'Das Wiederholungsmuster ist nicht gültig.',
      'seriesChangeAlert': 'Möchten Sie die Änderungen, die an bestimmten Instanzen dieser Serie vorgenommen wurden, rückgängig machen und sie erneut an die gesamte Serie anpassen?',
      'createError': 'The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.',
      'sameDayAlert': 'Two occurrences of the same event cannot occur on the same day.',
      'editRecurrence': 'Edit Recurrence',
      'repeats': 'Repeats',
      'alert': 'Alert',
      'startEndError': 'The selected end date occurs before the start date.',
      'invalidDateError': 'The entered date value is invalid.',
      'blockAlert': 'Events cannot be scheduled within the blocked time range.',
      'ok': 'Ok',
      'yes': 'Ja',
      'no': 'Nein',
      'occurrence': 'Occurrence',
      'series': 'Series',
      'previous': 'Zurück',
      'next': 'Weiter',
      'timelineDay': 'Tagesansicht',
      'timelineWeek': 'Wochenansicht (MO-SO)',
      'timelineWorkWeek': 'Wochenansicht (MO-FR)',
      'timelineMonth': 'Timeline Month',
      'timelineYear': 'Timeline Year',
      'editFollowingEvent': 'Following Events',
      'deleteTitle': 'Termin löschen',
      'editTitle': 'Termin editieren',
      'beginFrom': 'Begin From',
      'endAt': 'End At',
      'expandAllDaySection': 'Expand-all-day-section',
      'collapseAllDaySection': 'Collapse-all-day-section'
    },
    'recurrenceeditor': {
      'none': 'Keine',
      'daily': 'Täglich',
      'weekly': 'Wöchentlich',
      'monthly': 'Monatlich',
      'month': 'Monat',
      'yearly': 'Jährlich',
      'never': 'Nie',
      'until': 'Bis',
      'count': 'Anzahl',
      'first': 'Erstes',
      'second': 'Zweites',
      'third': 'Drittes',
      'fourth': 'Viertes',
      'last': 'Letztes',
      'repeat': 'Wiederholen',
      'repeatEvery': 'Repeat every',
      'on': 'Repeat On',
      'end': 'End',
      'onDay': 'Day',
      'days': 'Day(s)',
      'weeks': 'Week(s)',
      'months': 'Month(s)',
      'years': 'Year(s)',
      'every': 'every',
      'summaryTimes': 'time(s)',
      'summaryOn': 'on',
      'summaryUntil': 'until',
      'summaryRepeat': 'Repeats',
      'summaryDay': 'day(s)',
      'summaryWeek': 'week(s)',
      'summaryMonth': 'month(s)',
      'summaryYear': 'year(s)',
      'monthWeek': 'Month Week',
      'monthPosition': 'Month Position',
      'monthExpander': 'Month Expander',
      'yearExpander': 'Year Expander',
      'repeatInterval': 'Repeat Interval',
      'summary': 'Titel'
    },
    'calendar': {
      'today': 'Heute'
    },
    },
    'fr-CH': {
      'schedule': {
        'day': 'Jour',
        'week': 'Semaine',
        'workWeek': 'Semaine de travail',
        'month': 'Mois',
        'year': 'Année',
        'agenda': 'Agenda',
        'weekAgenda': 'Agenda de la semaine',
        'workWeekAgenda': 'Agenda de la semaine de travail',
        'monthAgenda': 'Agenda du mois',
        'today': 'Aujourd`hui',
        'noEvents': 'Aucun rendez-vous',
        'emptyContainer': 'Aucun rendez-vous n`est prévu à cette date.',
        'allDay': 'Toute la journée',
        'start': 'Début',
        'end': 'Fin',
        'more': 'Plus',
        'close': 'Fermer',
        'cancel': 'Interrompre',
        'noTitle': '(aucun titre)',
        'delete': 'Supprimer',
        'deleteEvent': 'Ce rendez-vous',
        'deleteMultipleEvent': 'Supprimer plusieurs rendez-vous',
        'selectedItems': 'Items selected',
        'deleteSeries': 'Toute la série',
        'edit': 'Modifier',
        'editSeries': 'Toute la série',
        'editEvent': 'Modifier le rendez-vous',
        'createEvent': 'Définir',
        'subject': 'Sujet',
        'addTitle': 'Ajouter un titre',
        'moreDetails': 'Plus de détails',
        'save': 'Enregistrer',
        'editContent': 'Comment souhaitez-vous modifier le rendez-vous dans la série?',
        'deleteContent': 'Etes-vous sûr/sûre de vouloir supprimer cet évènement?',
        'deleteMultipleContent': 'Etes-vous sûr/sûre de vouloir supprimer les évènements sélectionnés?',
        'newEvent': 'Nouveau rendez-vous',
        'title': 'Titre',
        'location': 'Lieu',
        'description': 'Description',
        'timezone': 'Plage horaire',
        'startTimezone': 'Début de la plage horaire',
        'endTimezone': 'Fin de la plage horaire',
        'repeat': 'Répéter',
        'saveButton': 'Enregistrer',
        'cancelButton': 'Interrompre',
        'deleteButton': 'Supprimer',
        'recurrence': 'Répétition',
        'wrongPattern': 'Le modèle de répétition n`est pas valide.',
        'seriesChangeAlert': 'Souhaitez-vous annuler les modifications apportées à certaines instances de cette série et les adapter à nouveau à l`ensemble de la série ?',
        'createError': 'The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.',
        'sameDayAlert': 'Two occurrences of the same event cannot occur on the same day.',
        'editRecurrence': 'Edit Recurrence',
        'repeats': 'Repeats',
        'alert': 'Alert',
        'startEndError': 'The selected end date occurs before the start date.',
        'invalidDateError': 'The entered date value is invalid.',
        'blockAlert': 'Events cannot be scheduled within the blocked time range.',
        'ok': 'OK',
        'yes': 'Oui',
        'no': 'Non',
        'occurrence': 'Occurrence',
        'series': 'Séries',
        'previous': 'Retour',
        'next': 'Suivant',
        'timelineDay': 'Vue du jour',
        'timelineWeek': 'Vue de la semaine (lu-di)',
        'timelineWorkWeek': 'Vue de la semaine (lu-ve)',
        'timelineMonth': 'Timeline Month',
        'timelineYear': 'Timeline Year',
        'editFollowingEvent': 'Following Events',
        'deleteTitle': 'Supprimer le rendez-vous',
        'editTitle': 'Définir un rendez-vous',
        'beginFrom': 'Begin From',
        'endAt': 'End At',
        'expandAllDaySection': 'Expand-all-day-section',
        'collapseAllDaySection': 'Collapse-all-day-section'
        },
        'recurrenceeditor': {
        'none': 'Aucun',
        'daily': 'Tous les jours',
        'weekly': 'Toutes les semaines',
        'monthly': 'Tous les mois',
        'month': 'Mois',
        'yearly': 'Toutes les années',
        'never': 'Jamais',
        'until': 'Jusqu`à',
        'count': 'Nombre',
        'first': 'Premier',
        'second': 'Deuxième',
        'third': 'Troisième',
        'fourth': 'Quatrième',
        'last': 'Dernier',
        'repeat': 'Répéter',
        'repeatEvery': 'Repeat every',
        'on': 'Repeat On',
        'end': 'End',
        'onDay': 'Day',
        'days': 'Day(s)',
        'weeks': 'Week(s)',
        'months': 'Month(s)',
        'years': 'Year(s)',
        'every': 'every',
        'summaryTimes': 'time(s)',
        'summaryOn': 'on',
        'summaryUntil': 'until',
        'summaryRepeat': 'Repeats',
        'summaryDay': 'day(s)',
        'summaryWeek': 'week(s)',
        'summaryMonth': 'month(s)',
        'summaryYear': 'year(s)',
        'monthWeek': 'Month Week',
        'monthPosition': 'Month Position',
        'monthExpander': 'Month Expander',
        'yearExpander': 'Year Expander',
        'repeatInterval': 'Repeat Interval',
        'summary': 'Titre'
        },
        'calendar': {
        'today': 'Aujourd`hui'
        },
    },
    'it-CH': {
      'schedule': {
        'day': 'Giorno',
        'week': 'Settimana',
        'workWeek': 'Settimana lavorativa',
        'month': 'Mese',
        'year': 'Anno',
        'agenda': 'Agenda',
        'weekAgenda': 'Agenda della settimana',
        'workWeekAgenda': 'Agenda della settimana lavorativa',
        'monthAgenda': 'Agenda del mese',
        'today': 'Oggi',
        'noEvents': 'Nessun appuntamento',
        'emptyContainer': 'Non ci sono appuntamenti previsti per questo giorno.',
        'allDay': 'Tutto il giorno',
        'start': 'Inizio',
        'end': 'Fine',
        'more': 'Di più',
        'close': 'Chiudere',
        'cancel': 'Annullamento',
        'noTitle': '(nessun titolo)',
        'delete': 'Cancellare',
        'deleteEvent': 'Questo appuntamento',
        'deleteMultipleEvent': 'Cancellare più appuntamenti',
        'selectedItems': 'Items selected',
        'deleteSeries': 'Intera serie',
        'edit': 'Modificare',
        'editSeries': 'Intera serie',
        'editEvent': 'Modificare l`appuntamento',
        'createEvent': 'Creare',
        'subject': 'Oggetto',
        'addTitle': 'Aggiungere un titolo',
        'moreDetails': 'Altri dettagli',
        'save': 'Salvare',
        'editContent': 'Come desidera modificare l`appuntamento della serie?',
        'deleteContent': 'È sicuro di voler eliminare questo evento?',
        'deleteMultipleContent': 'È sicuro di voler eliminare gli eventi selezionati?',
        'newEvent': 'Nuovo appuntamento',
        'title': 'Titolo',
        'location': 'Posizione',
        'description': 'Descrizione',
        'timezone': 'Fascia oraria',
        'startTimezone': 'Inizio della fascia oraria',
        'endTimezone': 'Fine della fascia oraria',
        'repeat': 'Ripetere',
        'saveButton': 'Salvare',
        'cancelButton': 'Annullare',
        'deleteButton': 'Cancellare',
        'recurrence': 'Ripetizione',
        'wrongPattern': 'Il modello di ripetizione non è valido.',
        'seriesChangeAlert': 'Vuole annullare le modifiche apportate a istanze specifiche di questa serie e adattarle nuovamente all`intera serie?',
        'createError': 'The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.',
        'sameDayAlert': 'Two occurrences of the same event cannot occur on the same day.',
        'editRecurrence': 'Edit Recurrence',
        'repeats': 'Repeats',
        'alert': 'Alert',
        'startEndError': 'The selected end date occurs before the start date.',
        'invalidDateError': 'The entered date value is invalid.',
        'blockAlert': 'Events cannot be scheduled within the blocked time range.',
        'ok': 'OK',
        'yes': 'Si',
        'no': 'No',
        'occurrence': 'Occurrence',
        'series': 'Serie',
        'previous': 'Indietro',
        'next': 'Avanti',
        'timelineDay': 'Vista giorno',
        'timelineWeek': 'Vista settimana (lun-dom)',
        'timelineWorkWeek': 'Vista settimana (lun-ven)',
        'timelineMonth': 'Timeline Month',
        'timelineYear': 'Timeline Year',
        'editFollowingEvent': 'Following Events',
        'deleteTitle': 'cancellare un appuntamento',
        'editTitle': 'modificare l`appuntamento',
        'beginFrom': 'Begin From',
        'endAt': 'End At',
        'expandAllDaySection': 'Expand-all-day-section',
        'collapseAllDaySection': 'Collapse-all-day-section'
        },
        'recurrenceeditor': {
        'none': 'Nessun',
        'daily': 'Giornaliero',
        'weekly': 'Settimanale',
        'monthly': 'Mensile',
        'month': 'Mese',
        'yearly': 'Annuale',
        'never': 'Mai',
        'until': 'Fino a quando',
        'count': 'Numero',
        'first': 'Primo',
        'second': 'Secondo',
        'third': 'Terzo',
        'fourth': 'Quatro',
        'last': 'Ultimo',
        'repeat': 'Ripetere',
        'repeatEvery': 'Repeat every',
        'on': 'Repeat On',
        'end': 'End',
        'onDay': 'Day',
        'days': 'Day(s)',
        'weeks': 'Week(s)',
        'months': 'Month(s)',
        'years': 'Year(s)',
        'every': 'every',
        'summaryTimes': 'time(s)',
        'summaryOn': 'on',
        'summaryUntil': 'until',
        'summaryRepeat': 'Repeats',
        'summaryDay': 'day(s)',
        'summaryWeek': 'week(s)',
        'summaryMonth': 'month(s)',
        'summaryYear': 'year(s)',
        'monthWeek': 'Month Week',
        'monthPosition': 'Month Position',
        'monthExpander': 'Month Expander',
        'yearExpander': 'Year Expander',
        'repeatInterval': 'Repeat Interval',
        'summary': 'Titolo'
        },
        'calendar': {
        'today': 'Oggi'
        },
    },
    'en-GB': {
      'schedule': {
        'day': 'Day',
        'week': 'Week',
        'workWeek': 'Working week',
        'month': 'Month',
        'year': 'Year',
        'agenda': 'Agenda',
        'weekAgenda': 'Agenda of the week',
        'workWeekAgenda': 'Agenda of the working week',
        'monthAgenda': 'Agenda of the month',
        'today': 'Today',
        'noEvents': 'No appointments',
        'emptyContainer': 'There are no appointments scheduled for this day.',
        'allDay': 'All day',
        'start': 'Start',
        'end': 'End',
        'more': 'More',
        'close': 'Close',
        'cancel': 'Cancel',
        'noTitle': '(no title)',
        'delete': 'Delete',
        'deleteEvent': 'This appointment',
        'deleteMultipleEvent': 'Delete multiple appointments',
        'selectedItems': 'Items selected',
        'deleteSeries': 'Whole series',
        'edit': 'Edit',
        'editSeries': 'Whole series',
        'editEvent': 'Edit appointment',
        'createEvent': 'Create',
        'subject': 'Subject',
        'addTitle': 'Add title',
        'moreDetails': 'More details',
        'save': 'Save',
        'editContent': 'How would you like to change the event in the series?',
        'deleteContent': 'Are you sure you want to delete this event?',
        'deleteMultipleContent': 'Are you sure you want to delete the selected events?',
        'newEvent': 'New event',
        'title': 'Title',
        'location': 'Location',
        'description': 'Description',
        'timezone': 'Timezone',
        'startTimezone': 'Start Timezone',
        'endTimezone': 'End Timezone',
        'repeat': 'Repeat',
        'saveButton': 'Save',
        'cancelButton': 'Cancel',
        'deleteButton': 'Delete',
        'recurrence': 'Repeat',
        'wrongPattern': 'The repeat pattern is not valid.',
        'seriesChangeAlert': 'Do you want to undo the changes made to specific instances of this series and fit them again to the whole series?',
        'createError': 'The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.',
        'sameDayAlert': 'Two occurrences of the same event cannot occur on the same day.',
        'editRecurrence': 'Edit Recurrence',
        'repeats': 'Repeats',
        'alert': 'Alert',
        'startEndError': 'The selected end date occurs before the start date.',
        'invalidDateError': 'The entered date value is invalid.',
        'blockAlert': 'Events cannot be scheduled within the blocked time range.',
        'ok': 'OK',
        'yes': 'Yes',
        'no': 'No',
        'occurrence': 'Occurrence',
        'series': 'Series',
        'previous': 'Back',
        'next': 'Next',
        'timelineDay': 'Day view',
        'timelineWeek': 'Week view (MO-SU)',
        'timelineWorkWeek': 'Week view (MO-FR)',
        'timelineMonth': 'Timeline Month',
        'timelineYear': 'Timeline Year',
        'editFollowingEvent': 'Following Events',
        'deleteTitle': 'Delete appointment',
        'editTitle': 'Edit appointment',
        'beginFrom': 'Begin From',
        'endAt': 'End At',
        'expandAllDaySection': 'Expand-all-day-section',
        'collapseAllDaySection': 'Collapse-all-day-section'
        },
        'recurrenceeditor': {
        'none': 'None',
        'daily': 'Daily',
        'weekly': 'Weekly',
        'monthly': 'Monthly',
        'month': 'Month',
        'yearly': 'Yearly',
        'never': 'Never',
        'until': 'Until',
        'count': 'Number',
        'first': 'First',
        'second': 'Second',
        'third': 'Third',
        'fourth': 'Fourth',
        'last': 'Last',
        'repeat': 'Repeat',
        'repeatEvery': 'Repeat every',
        'on': 'Repeat On',
        'end': 'End',
        'onDay': 'Day',
        'days': 'Day(s)',
        'weeks': 'Week(s)',
        'months': 'Month(s)',
        'years': 'Year(s)',
        'every': 'every',
        'summaryTimes': 'time(s)',
        'summaryOn': 'on',
        'summaryUntil': 'until',
        'summaryRepeat': 'Repeats',
        'summaryDay': 'day(s)',
        'summaryWeek': 'week(s)',
        'summaryMonth': 'month(s)',
        'summaryYear': 'year(s)',
        'monthWeek': 'Month Week',
        'monthPosition': 'Month Position',
        'monthExpander': 'Month Expander',
        'yearExpander': 'Year Expander',
        'repeatInterval': 'Repeat Interval',
        'summary': 'Title'
        },
        'calendar': {
        'today': 'Today'
        },
    }
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      'sr',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/sr.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'excel',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/excel.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'external-comment',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/external-comment.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'internal-comment',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/internal-comment.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'internal-comment-important',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/internal-comment-important.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'comment',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/comment.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'comment-unseen',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/comment-unseen.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'exchange',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/exchange.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'exchange-filled',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/exchange-filled.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'suit',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/suit.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'suit-filled',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/suit-filled.svg')
    );
   }
}
