import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ListItemModel, SearchUserModel } from '../../core/models/api-models';
import { UserService } from '../../core/services/api-services';

@Component({
  selector: 'app-user-autocomplete',
  templateUrl: './user-autocomplete.component.html',
  styleUrls: ['./user-autocomplete.component.scss']
})
export class UserAutocompleteComponent {
  @Input() itemControl!: FormControl;
  @Input() label!: string;
  @Input() error: string | null = null;
  @Input() isWeibel: boolean | null = null;
  @Input() isGuide: boolean | null = null;
  @Input() excludeIds: string[] | null = null;
  @Input() minCharacters: number = 0;
  @Output() selected = new EventEmitter<ListItemModel>();

  constructor(private readonly userService: UserService) { }

  search = (value: string | null) => {
    return this.userService.search({
      id: null,
      searchText: value,
      isWeibel: this.isWeibel,
      isGuide: this.isGuide,
      excludeIds: this.excludeIds
    } as SearchUserModel);
  }

  getItemName = (item: any) => {
    return (item as ListItemModel)?.name;
  }

  onSelected(value: any) {
    this.selected.emit(value);
  }
}
