import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { MessageService } from 'src/app/core/services/message.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DirtyDialogComponent } from 'src/app/core/components/dirty-dialog.component';

@Component({
  selector: 'app-statistic-query-name-dialog',
  templateUrl: './statistic-query-name-dialog.component.html',
  styleUrls: ['./statistic-query-name-dialog.component.scss']
})
export class StatisticQueryNameDialogComponent extends DirtyDialogComponent implements OnInit {
  formGroup = new FormGroup({
    name: new FormControl<string | null>(null, [Validators.required])
  });

  data: any;

  constructor(
    protected override readonly matDialogRef: MatDialogRef<StatisticQueryNameDialogComponent>,
    protected override readonly messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    super(messageService, matDialogRef);
    this.data = data;

  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.initDirtyCheck(this.formGroup);
  }

  onSave(): void {
    this.nameControl.updateValueAndValidity();

    if (this.nameControl.valid) {
      this.matDialogRef.close(this.nameControl.value as string);
    }
  }

  get nameControl(): FormControl {
    return this.formGroup.get('name') as FormControl;
  }
}
