import { AbstractControl, ValidatorFn } from '@angular/forms';

// Uses a validator only if the predicate is true.
// Use valueChanges() and updateValueAndValidit() on the control if it depends on another controls value.
export function conditionalValidator(predicate: (control: AbstractControl) => boolean, validator: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.parent) {
      return null;
    }

    if (predicate(control)) {
      return validator(control);
    }

    return null;
  };
}
