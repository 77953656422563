import { AbstractControl, ValidatorFn } from '@angular/forms';

export function largerThanOrEqualValidator(largerThanOrEqualControl: () => AbstractControl): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    // Do not check if the formGroup has not been initialized yet
    if (!control.parent) {
      return null;
    }

    const otherControl = largerThanOrEqualControl();

    if (control.value !== null && otherControl.value !== null && control.value <= otherControl.value) {
        return { largerThan: true };
      }
  
    return null;
  };
}
