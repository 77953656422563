import { OnDestroy, Directive } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare module 'rxjs' {
  interface Observable<T> {
    unsubscribeOnDestroy(this: Observable<T>, component: BaseComponent): Observable<T>;
  }
}

Observable.prototype.unsubscribeOnDestroy = function<T> (this: Observable<T>, component: BaseComponent): Observable<T> {
  return this.pipe(takeUntil(component.ngUnsubscribe));
};

// TODO: Add Angular decorator.
@Directive()
export class BaseComponent implements OnDestroy {
  ngUnsubscribe = new Subject<void>();
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public hasError = (control: FormControl, errorName: string): boolean => {
    return control.hasError(errorName);
  }
}

