import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { DateAdapter, MatDateFormats, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorMessageComponent } from './messages/error-message/error-message.component';
import { NotificationMessageComponent } from './messages/notification-message/notification-message.component';
import { SuccessMessageComponent } from './messages/success-message/success-message.component';
import { ConfirmMessageComponent } from './messages/confirm-message/confirm-message.component';
import { PeopleDetailComponent } from './people-detail/people-detail.component';
import { BoolFilterComponent } from './filter-components/bool-filter/bool-filter.component';
import { DateTimeFilterComponent } from './filter-components/date-time-filter/date-time-filter.component';
import { GuidFilterComponent } from './filter-components/guid-filter/guid-filter.component';
import { NumberFilterComponent } from './filter-components/number-filter/number-filter.component';
import { StringFilterComponent } from './filter-components/string-filter/string-filter.component';
import { EmailChiplistComponent } from './email-chiplist/email-chiplist.component';
import { EventRoomSearchComponent } from './event-room-search/event-room-search.component';
import { MeetingSerieRoomSearchComponent } from './meeting-serie-room-search/meeting-serie-room-search.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { UploadComponent } from './upload/upload.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { GuidListFilterComponent } from './filter-components/guid-list-filter/guid-list-filter.component';
import { CouncilMemberDetailComponent } from './council-member-detail/council-member-detail.component';
import { EnumFilterComponent } from './filter-components/enum-filter/enum-filter.component';
import { AutoCompleteSelectComponent } from './auto-complete-select/auto-complete-select.component';
import { UserAutocompleteComponent } from './user-autocomplete/user-autocomplete.component';
import { ProcedureListComponent } from './procedure-list/procedure-list.component';
import { GuideCapacityDialogComponent } from './guide-capacity/guide-capacity.component';
import { LoaderComponent } from './loader/loader.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule, MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRippleModule } from '@angular/material/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { PostalCodeComponent } from './postal-code/postal-code.component';
import { TimePickerInputComponent } from './time-picker-input/time-picker-input.component';
import { TransferParticipantsDialogComponent } from '../private/shared/transfer-participants/transfer-participants.component';
import { BasketExpiresDialogComponent } from './basket-expires-dialog/basket-expires-dialog.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { StatisticQueryNameDialogComponent } from './statistic-query-name-dialog/statistic-query-name-dialog.component';
import { FloatingLanguageControlComponent } from '../private/shared/floating-language-control/floating-language-control/floating-language-control.component';
import { CetDatePipe } from '../core/pipes/cet-date.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';


const angularSharedModules = [
  MatSnackBarModule, // Required by MessageService
  MatButtonModule, // Required by MessageComponents
  MatMenuModule, // Required by language selector
  MatIconModule, // Required by language selector
  MatTooltipModule, // Required by language selector
  MatToolbarModule,
  MatFormFieldModule,
  MatSidenavModule,
  MatCheckboxModule,
  MatDividerModule,
  MatExpansionModule,
  MatInputModule, // Required by share-dialog
  MatStepperModule,
  MatSelectModule,
  MatDatepickerModule,
  MatSlideToggleModule,
  MatRadioModule,
  FormsModule,
  MatAutocompleteModule,
  MatTableModule,
  MatDialogModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatSortModule,
  ReactiveFormsModule,
  MatProgressSpinnerModule,
  MatBadgeModule,
  MatChipsModule,
  MatCardModule,
  MatListModule,
  MatTabsModule,
  MatRippleModule,
  MatButtonToggleModule,
  MatSliderModule,
  DragDropModule
]

const exportComponents = [
  ErrorMessageComponent,
  NotificationMessageComponent,
  SuccessMessageComponent,
  ConfirmMessageComponent,
  BoolFilterComponent,
  DateTimeFilterComponent,
  GuidFilterComponent,
  EnumFilterComponent,
  GuidListFilterComponent,
  NumberFilterComponent,
  StringFilterComponent,
  EmailChiplistComponent,
  EventRoomSearchComponent,
  MeetingSerieRoomSearchComponent,
  UploadComponent,
  AutoCompleteSelectComponent,
  UserAutocompleteComponent,
  LoaderComponent,
  SpinnerComponent,
  TimePickerInputComponent,
  BasketExpiresDialogComponent,
  LanguageSelectorComponent,
  StatisticQueryNameDialogComponent
]

const formFieldAppearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

export const CUSTOM_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    exportComponents,
    PeopleDetailComponent,
    CouncilMemberDetailComponent,
    ProcedureListComponent,
    GuideCapacityDialogComponent,
    PostalCodeComponent,
    TransferParticipantsDialogComponent,
    FloatingLanguageControlComponent,
    CetDatePipe
  ],
  imports: [
    CommonModule,
    angularSharedModules,
    TranslateModule,
    NgxFileDropModule,
  ],
  exports: [
    CommonModule,
    angularSharedModules,
    TranslateModule,
    exportComponents,
    PeopleDetailComponent,
    CouncilMemberDetailComponent,
    ProcedureListComponent,
    PostalCodeComponent,
    TransferParticipantsDialogComponent,
    FloatingLanguageControlComponent,
    CetDatePipe
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: formFieldAppearance },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'de-CH' },
    
  ]

})
export class SharedModule {

}
