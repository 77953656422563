import { OnInit, Directive } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from './base.component';

@Directive()
export abstract class BaseDialogComponent extends BaseComponent implements OnInit {
  constructor(
    protected readonly matDialogRef: MatDialogRef<any>
  ) {
    super();
  }

  ngOnInit(): void {
    this.matDialogRef.backdropClick()
      .unsubscribeOnDestroy(this)
      .subscribe(_ => {
        this.close();
      });
  }

  close(): void {
    this.matDialogRef.close();
  }
}

