import { ValidatorFn } from '@angular/forms';
import { AbstractControl } from '@angular/forms';

// Returns a validation object if the hasErrorCheck returns true.
export function customValidator(hasErrorCheck: (control: AbstractControl) => boolean, returnValue: (checkResult: any) => { [key: string]: any } | null): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    // Do not check if the formGroup has not been initialized yet
    if (!control.parent) {
      return null;
    }

    const checkResult = hasErrorCheck(control);

    if (checkResult) {
      return returnValue(control.value);
    }

    return null;
  };
}
