import { AbstractControl, ValidatorFn } from '@angular/forms';

export function smallerThenDateValidator(smallerThenDateControl: () => AbstractControl): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    // Do not check if the formGroup has not been initialized yet
    if (!control.parent) {
      return null;
    }

    const otherControl = smallerThenDateControl();
    if (control.value && otherControl.value && new Date(control.value) > new Date(otherControl.value)) {
      return { smallerThenDate: true };
    }

    return null;
  };
}
