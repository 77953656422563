<mat-icon matTooltip="{{ 'Common.Filters.FilterGuidList' | translate }}" [matMenuTriggerFor]="menu">filter_alt</mat-icon>

<mat-menu #menu="matMenu">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <form [formGroup]="filterForm" (click)="$event.stopPropagation();">
                    <mat-form-field>
                        <mat-label>{{ 'Common.Filters.FilterType' | translate }}</mat-label>
                        <mat-select [formControl]="filterTypeControl" [value]="filterTypeControl"
                            (selectionChange)="onFilterChange($event)">
                            <mat-option *ngFor="let option of filterOptions" [value]="option.value">
                                {{ option.translationKey | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div>
        </div>

        <ng-container *ngIf="this.currentFilter.value !== guidContainsAllFilter; else arrayFilter">
            <div class="row">
                <div class="col-12">
                    <form [formGroup]="guidListForm" (click)="$event.stopPropagation();">
                        <mat-form-field>
                            <mat-label *ngIf="filterSelectText">{{ filterSelectText | translate }}</mat-label>
                            <mat-label *ngIf="!filterSelectText">{{ 'Common.Filters.ChooseValue' | translate }}
                            </mat-label>
                            <mat-select [formControl]="guidControl">
                                <mat-option *ngFor="let option of options" [value]="option.id">
                                    {{option.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
            </div>
        </ng-container>
        <ng-template #arrayFilter>
            <div class="row">
                <div class="col-12">
                    <form [formGroup]="multipleGuidListForm" (click)="$event.stopPropagation();">
                        <mat-form-field>
                            <mat-label *ngIf="filterSelectText">{{ filterSelectText | translate }}</mat-label>
                            <mat-label *ngIf="!filterSelectText">{{ 'Common.Filters.ChooseValue' | translate }}
                            </mat-label>
                            <mat-select [formControl]="multipleGuidControl" multiple>
                                <mat-option *ngFor="let option of options" [value]="option.id">{{option.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
            </div>
        </ng-template>

        <div class="row">
            <div class="col" style="display: flex; justify-content: flex-end;">
                <mat-icon matTooltip="{{ 'Common.Filters.Cancel' | translate }}" style="cursor: pointer;"
                    color="primary" (click)="onCancel()">
                    clear
                </mat-icon>
                <mat-icon matTooltip="{{ 'Common.Filters.RunFilter' | translate }}" style="cursor: pointer;"
                    color="primary" (click)="onSubmit($event)">
                    check
                </mat-icon>
                <mat-icon matTooltip="{{ 'Common.Filters.Clear' | translate }}" style="cursor: pointer;" color="primary"
                    *ngIf="this.currentValue | async" (click)="onClear()">
                    delete
                </mat-icon>
            </div>
        </div>
    </div>
</mat-menu>