import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss']
})
export class NotificationMessageComponent implements OnInit {

  constructor(private matSnackBarRef: MatSnackBarRef<NotificationMessageComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public texts: any[]) { }

  ngOnInit(): void {
  }
  dismiss() {
    this.matSnackBarRef.dismiss();
  }

}
