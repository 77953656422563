<mat-form-field class="w-100">
  <mat-label>{{ label }}</mat-label>
  <input matInput
         maxlength="100"
         autocomplete="off"
         [formControl]="itemControl"
         [matAutocomplete]="auto"
         (focus)="onFocus()">
  <mat-autocomplete [displayWith]="getDisplayName"
                    (optionSelected)="onItemSelected($event)"
                    #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredItems | async" [value]="option">
      {{ getItemName(option) }}
    </mat-option>
  </mat-autocomplete>
  <button mat-icon-button
          matSuffix
          color="primary"
          type="button"
          (click)="onRemoveSelectedItem()">
    <mat-icon>clear</mat-icon>
  </button>
  <mat-error>{{ error}}</mat-error>
</mat-form-field>
