import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requestCount = 0;
  private blockCount = 0;

  constructor(private readonly loaderService: LoaderService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.showLoader();

    const block = this.shouldShowBlocker(request);

    if (block) {
      this.showBlocker();
    }

    return next.handle(request)
      .pipe(finalize(() => {
        this.hideLoader();

        if (block) {
          this.hideBlocker();
        }
      }));
  }

  private showLoader(): void {
    this.requestCount++;
    this.loaderService.show();
  }

  private hideLoader(): void {
    this.requestCount--;
    if (this.requestCount === 0) {
      this.loaderService.hide();
    }
  }

  private showBlocker(): void {
    this.blockCount++;
    this.loaderService.block();
  }

  private hideBlocker(): void {
    this.blockCount--;

    if (this.blockCount === 0) {
      this.loaderService.unblock();
    }
  }

  private shouldShowBlocker(request: HttpRequest<any>): boolean {
    // For saving operations but not for paging requests
    return (request.method === 'POST' || request.method === 'PATCH') && request.url.indexOf('Paged') < 0 && request.url.indexOf('List') < 0;
  }
}
