import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';

export function internationalGooglePhoneValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const phoneNumberUtil = PhoneNumberUtil.getInstance();
      const phoneNumberInput = control.value;
      if ((phoneNumberInput || '').trim().length !== 0) {
        try {
          const phoneNumber = phoneNumberUtil.parse(phoneNumberInput);
          if (!phoneNumberUtil.isValidNumber(phoneNumber)) {
              return { 'phoneNumberInvalid': true };
          }
        } catch (error) {
          return { 'phoneNumberInvalid': true };
        }
      }
    }
    return null;
  };
}
