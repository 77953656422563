import { Directive, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MessageService } from '../services/message.service';
import FormHelper from '../helpers/form.helper';
import { FormGroup } from '@angular/forms';
import { DirtyComponent } from './dirty.component';
import { BaseDialogComponent } from './base-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';

@Directive()
export abstract class DirtyDialogComponent extends BaseDialogComponent implements DirtyComponent, OnInit {
  public isDirty: Observable<boolean> = new Observable<boolean>(subscriber => subscriber.next(this.dirtyCheck ? this.dirtyCheck() : false));
  private dirtyCheck?: () => boolean;

  constructor(protected readonly messageService: MessageService,
    matDialogRef: MatDialogRef<any>
  ) {
    super(matDialogRef);
  }

  override ngOnInit(): void {
    FormHelper.dirtyCheckBeforeUnload(this.isDirty, this);

    this.matDialogRef.disableClose = true;
    this.matDialogRef.backdropClick()
      .unsubscribeOnDestroy(this)
      .subscribe((value: MouseEvent) => {
        this.cancel(() => this.close(), () => value.preventDefault());
      });
  }

  protected initDirtyCheck(formGroup: FormGroup) {
    this.dirtyCheck = () => formGroup.dirty;
  }

  protected initDirtyCheckMultiple(formGroups: () => FormGroup[]) {
    this.dirtyCheck = () => FormHelper.dirtyCheckMultipleFormGroups(formGroups);
  }

  protected cancel(onOk?: () => void, onCancel?: () => void): void {
    this.messageService.openDirty(this)
      .unsubscribeOnDestroy(this)
      .subscribe(value => {
        if (value && onOk) {
          setTimeout(() => onOk());
        }
        else if (onCancel) {
          setTimeout(() => onCancel());
        }
      });
  }

  onCancel(): void {
    this.cancel(() => this.close());
  }
}

