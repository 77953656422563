<mat-dialog-content>
  <div class="row">
    <div class="col">
      {{ 'Common.Messages.RenewBasket' | translate }}
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="row">
    <div class="col-12">
      <button mat-raised-button class="me-3" (click)="onRenew()" color="primary">
        {{ 'Common.Renew' | translate}}
      </button>
      <button mat-raised-button (click)="onCancel()">
        {{ 'Common.Close' | translate}}
      </button>
    </div>
  </div>
</mat-dialog-actions>