import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private readonly loaderSubject = new Subject<boolean>();
  public readonly loaderState = this.loaderSubject.asObservable();

  private readonly blockerSubject = new Subject<boolean>();
  public readonly blockerState = this.blockerSubject.asObservable();

  constructor() { }

  show() {
    this.loaderSubject.next(true);
  }

  hide() {
    this.loaderSubject.next(false);
  }

  block() {
    this.blockerSubject.next(true);
  }

  unblock() {
    this.blockerSubject.next(false);
  }
}
