import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, filter, finalize, Observable, Subject, switchMap, take, tap, throwError } from 'rxjs';
import { AuthenticatedModel } from '../models/api-models';
import { ErrorMessage } from '../models/error-message.model';
import { MessageService } from '../services/message.service';
import { PrivateAuthenticationService } from '../services/private-authentication.service';
import { PublicAuthenticationService } from '../services/public-authentication.service';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshedToken$ = new Subject<string | null>();

  constructor(
    private messageService: MessageService,
    private privateAuthenticationService: PrivateAuthenticationService,
    private publicAuthenticationService: PublicAuthenticationService,
    private readonly router: Router
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> | any {
    return next.handle(request).pipe(
      catchError((error: any) => {
        if (error.error instanceof ErrorEvent) {
          this.messageService.openErrorLabel('Common.Messages.UnexpectedError');
          return throwError(() => error.error);
        } else {
          switch (error.status) {
            case 401:
              return this.handle401Error(request, next);
            case 403:
              break;
            case 400:
            case 404:
            case 405:
            case 409:
            case 500:
              if (error.error?.size) {
                const reader = new FileReader();
                reader.onload = () => {
                  const details = JSON.parse(reader?.result as string) as ErrorMessage;

                  if (details.detail) {
                    this.messageService.openErrorMessage(details.detail);
                  }
                  else {
                    this.messageService.openErrorLabel('Common.Messages.UnexpectedError');
                  }
                };

                reader.readAsText(error.error);
              } else {
                this.messageService.openErrorLabel('Common.Messages.UnexpectedError');
              }
              break;
            default:
              this.messageService.openErrorLabel('Common.Messages.UnexpectedError');
              break;
          }
        }
        return throwError(() => error);
      })
    );
  }

  parseErrorBlob(err: HttpErrorResponse):
    Observable<ErrorMessage> {
    var subject = new Subject<ErrorMessage>();

    let reader = new FileReader();
    reader.onload = () => {
      subject.next(JSON.parse(reader?.result as string));
    };

    reader.readAsText(err.error);

    return subject.asObservable();
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      // Cancel previous pending requests
      this.refreshedToken$.next(null);

      const splittedUrl = this.router.url.replace('https://', '').replace('http://', '').split('/');
      const isPrivateRequest = splittedUrl.length > 1 && splittedUrl[1] == 'private';
      const authenticationService = isPrivateRequest ?
      this.privateAuthenticationService : 
      this.publicAuthenticationService;
      let redirectUrl = isPrivateRequest ? 
        'private/login' : 
        'login';

      return authenticationService.refreshToken()
      .pipe(switchMap((result: AuthenticatedModel | null) => {
        this.refreshedToken$.next(result?.token ?? null);
        
        if (result?.token) {
          return next.handle(this.addToken(request, result.token));
        }
        else {
          // Return error and go into catchError to redirect
          return throwError(() => new Error('No token received.'));
        }
      }),
      catchError(error => {
        authenticationService.logout();
        // Navigate to login page if refresh failed
        this.router.navigate([redirectUrl]);

        // Cancel pending requests
        this.refreshedToken$.next(null);

        return throwError(() => error);
      }),
      finalize(() => {
        this.isRefreshing = false; 
      }));
    }
    else {
      // Let other requests wait on the refreshed token
      return this.refreshedToken$.pipe(
        take(1),
        switchMap(token => {
            if (token) {
                return next.handle(this.addToken(request, token));
            }

            // Also "end" the request even when the token refresh failed
            return throwError(() => new Error('No token received.'));
        }));
    }
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}
